import type { Reducer } from "redux";
import { Coin } from "App/src/types/api/Coin";
import { GET_TOKEN_INFO, SHOW_COINS_LOADER, GET_COIN_XRATE, COINS_ERROR, GET_COINS_LIST_REQUEST, GET_COINS_LIST_SUCCESS, GET_COINS_LIST_ERROR } from "../actions/types/types";

// const SaxonData = window.SaxonData?.Admin;
interface IState {
	coins: Coin[];
	coinXRate: Obj;
	loading: boolean;
	sidebarLoading: boolean | null;
	error: any;
}

const initialState: IState = {
	coins: [],
	coinXRate: {},
	loading: true,
	sidebarLoading: null,
	error: null,
};

export const coinReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case SHOW_COINS_LOADER:
			return {
				...state,
				loading: true,
			};
		case GET_TOKEN_INFO:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				...payload,
			};
		case GET_COIN_XRATE:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				...payload,
			};
		case COINS_ERROR:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				error: payload,
			};
		case GET_COINS_LIST_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_COINS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				...payload,
			};
		case GET_COINS_LIST_ERROR:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				error: payload,
			};
		default:
			return state;
	}
};

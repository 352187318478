import type { Reducer } from "@reduxjs/toolkit";
import {
	GET_ADMIN_DEALS_FAILURE,
	GET_ADMIN_DEALS_REQUEST,
	GET_ADMIN_DEALS_SUCCESS,
	GET_ADMIN_DEAL_FAILURE,
	GET_ADMIN_DEAL_REQUEST,
	GET_ADMIN_DEAL_SUCCESS,
	CREATE_ADMIN_DEAL_FAILURE,
	CREATE_ADMIN_DEAL_REQUEST,
	CREATE_ADMIN_DEAL_SUCCESS,
	DELETE_ADMIN_DEAL_FAILURE,
	DELETE_ADMIN_DEAL_REQUEST,
	DELETE_ADMIN_DEAL_SUCCESS,
	UPDATE_ADMIN_DEAL_FAILURE,
	UPDATE_ADMIN_DEAL_REQUEST,
	UPDATE_ADMIN_DEAL_SUCCESS,
} from "Actions/types/adminDeal";
import { SaxonDataCompany } from "Types/SaxonData/SD_Company";

interface IState extends SaxonDataCompany {
	loading: boolean;
	error: string;
	deleted: string[];
}

const initialState: IState = {
	loading: true,
	error: null,
	companyid: window?.SaxonData?.admin?.company?.companyid || null,
	userid: window?.SaxonData?.admin?.company?.userid || null,
	company: window?.SaxonData?.admin?.company?.company || null,
	deals: window?.SaxonData?.admin?.company?.deals || [],
	sectors: window?.SaxonData?.sectors || [],
	deleted: [],
};

export const adminDealReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_ADMIN_DEALS_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_ADMIN_DEALS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_ADMIN_DEALS_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case GET_ADMIN_DEAL_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_ADMIN_DEAL_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_ADMIN_DEAL_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case CREATE_ADMIN_DEAL_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case CREATE_ADMIN_DEAL_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case CREATE_ADMIN_DEAL_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case DELETE_ADMIN_DEAL_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case DELETE_ADMIN_DEAL_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case DELETE_ADMIN_DEAL_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case UPDATE_ADMIN_DEAL_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case UPDATE_ADMIN_DEAL_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case UPDATE_ADMIN_DEAL_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		default:
			return state;
	}
};

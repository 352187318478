import React from "react";
import PropTypes from "prop-types";
import Testimonials from "./Testimonials";
import Divider from "Components/universal/Divider";
import BlogPosts from "./BlogPosts";
import RegisterDapp from "./RegisterDapp";
import "App/src/scss/custom/pages/landing.lazy.scss";

const Landing = (props) => {
	return (
		<div>
			{/* INTRODUCTION */}
			<Divider />
			<section className="section bg-pattern-dots-4 py-5 my-0 px-0 min-vh-100 d-flex align-items-center">
				<div className="container-slim-xxl py-3">
					<div className="row py-5">
						<div className="col-12 px-3 ">
							<div className="my-md-4">
								<div className="text-white  mb-4 mt-3 hero-title ">
									<span className="text-info fs-2 fw-700 ff-header ">Introduction</span>
									<br />
									<br />
									<p className="fs-2 fw-300 text-white pt-2 pb-3">{props.companyDescription}</p>
									<br />
									<div className="fs-4 fw-500 ff-sans-serif-alt w-100 d-flex flex-column flex-md-row pb-3">
										<div className="pr-0 py-2 pr-lg-4 pr-md-3">
											<span className="pr-1">Est.</span>
											<span className="text-info">{props.stats.yearFounded}</span>
										</div>
										<div className="px-lg-4 px-md-3 py-2 border-xs-none border-sm-none  border-md-none border-lg-none border-x-1-light border-y-xl-none">
											<span className="pr-1">
												<span className="text-info" data-countup='{"startVal":0,"suffix":"+"}' data-to={props.stats.projectsCount} data-aos data-aos-id="countup:in">
													00
												</span>
											</span>
											<span>projects</span>
										</div>
										<div className="ps-0 py-2 ps-lg-4 ps-md-4">
											<span className="pr-1 text-info">
												$
												<span className="" data-countup='{"startVal":0}' data-to={props.stats.fundingValue} data-aos data-aos-id="countup:in">
													000
												</span>
												m+
											</span>
											<span>in funding</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row row-cols-xl-3 row-cols-1 ">
						<div className="col px-3 pt-2 pb-3">
							<div className="d-xl-none">
								<div className="bg-light mb-3 w-100" style={{ height: 1 }} />
							</div>
							<div className="lead text-info-alt-lighten-1 fs-5 fw-400 text-uppercase">Advisors</div>
							<div className="lead fs-3 text-white py-2">Corporate Finance Advisory</div>
							<div className="fs-4 text-sg-400  py-2">We help companies and foundations to prepare for and execute private placements of crypto assets.</div>
						</div>
						<div className="col border-xs-none border-sm-none  border-md-none border-lg-none border-x-1-light border-y-xl-none   px-3 py-3">
							<div className="d-xl-none">
								<div className="bg-light my-3 w-100" style={{ height: 1 }} />
							</div>
							<div className="lead text-info-alt-lighten-1 fs-5 fw-400 text-uppercase">Ventures</div>
							<div className="lead fs-3 text-white py-2">Early-Stage Investment</div>
							<div className="fs-4 text-sg-400 py-2">We invest in seed stage crypto projects that are led by exceptional founders with big ideas.</div>
						</div>
						<div className="col px-3 py-3">
							<div className="d-xl-none">
								<div className="bg-light my-3 w-100" style={{ height: 1 }} />
							</div>
							<div className="lead text-info-alt-lighten-1 fs-5 fw-400 text-uppercase">Nodes</div>
							<div className="lead fs-3 text-white py-2">Network Participation</div>
							<div className="fs-4 text-sg-400  py-2">We run infrastructure, build technology and provide staking-as-a-service on promising networks.</div>
						</div>
						<div className="col  px-3 py-3">
							<RegisterDapp />
						</div>
					</div>
				</div>
			</section>

			{/* TESTIMONIALS */}
			<Divider />
			<section className="section bg-pattern-dots-4 my-0 py-5 px-0 min-vh-100 d-flex align-items-center">
				<div className="container-slim-xxl py-5">
					<div className="d-flex justify-content-center align-items-center">
						<div className="text-center fs-1 py-4 fw-700 text-white mb-4 bg-gradient-radial-sm ">Testimonials</div>
					</div>
					<div className="px-3 px-lg-0">
						<Testimonials />
					</div>
				</div>
			</section>

			{/* FEATURES */}
			<Divider />
			<section className="section bg-pattern-dots-4 my-0 py-5 px-0 min-vh-100  d-flex justify-content-center">
				<div className="container-slim-xxl py-5">
					<div className="d-flex justify-content-center align-items-center">
						<div className="text-center fs-1 py-4 fw-700 text-white mb-4 bg-gradient-radial-sm ">Features</div>
					</div>
					<div className="fs-3 text-center pb-4">
						<span>Saxon has </span>
						<span className="text-info-alt">high-quality projects</span>
						<span className="px-1">with</span>
						<span className="text-info-alt">unique business models</span>
						<span className="px-1">that</span>
						<span className="text-info-alt">create value</span>
					</div>

					<div className="row">
						<div className="col-lg-4" data-aos="zoom-in-up">
							<div className="text-center p-3">
								<div className="avatar-lg m-auto">
									<img src="https://saxoncircle.blob.core.windows.net/home-page/icon-7.svg" alt="kpis" />
								</div>
								<h4 className="mt-3 text-capitalize">Real-Time KPIs</h4>
								<p className="text-muted mt-2 mb-0">We track the critical, hard to gather company KPIs, providing investors with unparalleled insight and depth.</p>
							</div>
						</div>
						<div className="col-lg-4" data-aos="zoom-in-up">
							<div className="text-center p-3">
								<div className="avatar-lg m-auto">
									<img src="https://saxoncircle.blob.core.windows.net/home-page/icon-2.svg" alt="insights" />
								</div>
								<h4 className="mt-3 text-capitalize">Research Insights</h4>
								<p className="text-muted mt-2 mb-0">Institutional grade market research and insights from Saxon Team.</p>
							</div>
						</div>
						<div className="col-lg-4" data-aos="zoom-in-up">
							<div className="text-center p-3">
								<div className="avatar-lg m-auto">
									<img src="https://saxoncircle.blob.core.windows.net/home-page/icon-4.svg" alt="updates" />
								</div>
								<h4 className="mt-3 text-capitalize">Investor Updates</h4>
								<p className="text-muted mt-2 mb-0">Keep up to date with our deals, development and progress through written updates by companies.</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4" data-aos="zoom-in-up">
							<div className="text-center p-3">
								<div className="avatar-lg m-auto ">
									<img src="https://saxoncircle.blob.core.windows.net/home-page/icon-1.svg" alt="metrics" />
								</div>
								<h4 className="mt-3 text-capitalize">Live Metrics</h4>
								<p className="text-muted mt-2 mb-0">Visualise key project metrics, including developer and social stats.</p>
							</div>
						</div>
						<div className="col-lg-4" data-aos="zoom-in-up">
							<div className="text-center p-3">
								<div className="avatar-lg m-auto">
									<img src="https://saxoncircle.blob.core.windows.net/home-page/icon-6.svg" alt="docs" />
								</div>
								<h4 className="mt-3 text-capitalize">Company Documentation</h4>
								<p className="text-muted mt-2 mb-0">Read our investor tearsheet with analysis on the team, market, and project analysis.</p>
							</div>
						</div>
						<div className="col-lg-4" data-aos="zoom-in-up">
							<div className="text-center p-3">
								<div className="avatar-lg m-auto">
									<img src="https://saxoncircle.blob.core.windows.net/home-page/icon-3.svg" alt="members" />
								</div>
								<h4 className="mt-3 text-capitalize">Exclusive Member Community</h4>
								<p className="text-muted mt-2 mb-0">Converse and Collaborate with other Saxon Circle members using our Coffee House feature.</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4" data-aos="zoom-in-up">
							<div className="text-center p-3">
								<div className="avatar-lg m-auto">
									<img src="https://saxoncircle.blob.core.windows.net/home-page/icon-10.svg" alt="dashboards" />
								</div>
								<h4 className="mt-3 text-capitalize">Customisable Dashboards</h4>
								<p className="text-muted mt-2 mb-0">Create real-time custom dashboards to track the crypto market data that matters most.</p>
							</div>
						</div>
						<div className="col-lg-4" data-aos="zoom-in-up">
							<div className="text-center p-3">
								<div className="avatar-lg m-auto">
									<img src="https://saxoncircle.blob.core.windows.net/home-page/icon-5.svg" alt="meetings" />
								</div>
								<h4 className="mt-3 text-capitalize">Book Meetings</h4>
								<p className="text-muted mt-2 mb-0">Directly arrange a meeting with the Saxon Advisors research team and company management.</p>
							</div>
						</div>
						<div className="col-lg-4" data-aos="zoom-in-up">
							<div className="text-center p-3">
								<div className="avatar-lg m-auto">
									<img src="https://saxoncircle.blob.core.windows.net/home-page/icon-8.svg" alt="dataroom" />
								</div>
								<h4 className="mt-3 text-capitalize ">Project Data Room</h4>
								<p className="text-muted mt-2 mb-0">Data warehousing for our clients via our integration with docsend.</p>
							</div>
						</div>
					</div>
					<div className="row pt-2 pb-2">
						<div className="col-lg-12 text-center">
							<div className="fs-4 text-sg-500 mt-4">Register below to become a member and receive high quality analysis and opportunities.</div>
							<div className="pt-3">
								<RegisterDapp textContent={"Register"} />
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* SAXON UPDATES */}
			<Divider />
			<section className="section bg-pattern-dots-4 my-0 py-xl-5 py-lg-5 py-md-3 py-sm-2 py-2 px-0 min-vh-100">
				<div className="container-xl py-5">
					<div className="d-flex justify-content-center align-items-center">
						<div className="text-center fs-1 py-4 fw-700 text-white mb-4 bg-gradient-radial-sm ">Saxon Updates</div>
					</div>
					<BlogPosts articles={props.articles} />
				</div>
				<div className="container-slim-xxl py-5">
					<div className="text-center">
						<h3 className="ff-sans-serif-alt fw-500 fs-3 px-2 px-lg-0">
							Register now and become a <span className="text-info-alt ff-sans-serif-alt fw-700">Saxon Member</span>
						</h3>
						<div className="pt-3">
							<RegisterDapp textContent={"Register"} />
						</div>
					</div>
				</div>
			</section>
			<Divider />
		</div>
	);
};

Landing.defaultProps = {
	articles: window?.SaxonData?.articles || [],
	companyDescription: window?.SaxonData?.meta.COMPANY_DESCRIPTION || "",
	stats: window?.SaxonData?.meta.COMPANY_STATS || null,
};
Landing.propTypes = {
	articles: PropTypes.array,
	companyDescription: PropTypes.string,
	stats: PropTypes.object,
};

export default Landing;

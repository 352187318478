import React from 'react';

const Divider = () => {
    return (

        <div className="">
            <div className="bg-divider"></div>
        </div>
    );
};

export default Divider;
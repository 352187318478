import type { Reducer } from "@reduxjs/toolkit";

import {
	GET_COMPANIES_FAILURE,
	GET_COMPANIES_REQUEST,
	GET_COMPANIES_SUCCESS,
	GET_COMPANY_FAILURE,
	GET_COMPANY_REQUEST,
	GET_COMPANY_SUCCESS,
	CREATE_COMPANY_FAILURE,
	CREATE_COMPANY_REQUEST,
	CREATE_COMPANY_SUCCESS,
	DELETE_COMPANY_FAILURE,
	DELETE_COMPANY_REQUEST,
	DELETE_COMPANY_SUCCESS,
	UPDATE_COMPANY_FAILURE,
	UPDATE_COMPANY_REQUEST,
	UPDATE_COMPANY_SUCCESS,
	HAS_PIPEDRIVE_DEALS_REQUEST,
	HAS_PIPEDRIVE_DEALS_SUCCESS,
	HAS_PIPEDRIVE_DEALS_FAILURE,
} from "Actions/types/adminCompany";

import { SaxonDataCompanies } from "../../types/SaxonData/SD_Companies";

interface IState extends SaxonDataCompanies {
	loading: boolean;
	error: string;
	deleted: string[];
	has_pipedrive_deals: boolean | null;
}

const initialState: IState = {
	loading: true,
	error: null,
	deleted: [],
	userid: window?.SaxonData?.userid || null,
	companies: window?.SaxonData?.admin?.companies?.companies || [],
	sectors: window?.SaxonData?.sectors || [],
	countries: window?.SaxonData?.countries || [],
	has_pipedrive_deals: null,
};

export const adminCompanyReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_COMPANIES_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_COMPANIES_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_COMPANIES_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case GET_COMPANY_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_COMPANY_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_COMPANY_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case CREATE_COMPANY_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case CREATE_COMPANY_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case CREATE_COMPANY_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case DELETE_COMPANY_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case DELETE_COMPANY_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case DELETE_COMPANY_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case UPDATE_COMPANY_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case UPDATE_COMPANY_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case UPDATE_COMPANY_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case HAS_PIPEDRIVE_DEALS_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case HAS_PIPEDRIVE_DEALS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case HAS_PIPEDRIVE_DEALS_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		default:
			return state;
	}
};

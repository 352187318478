import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import moment from 'moment';

const BlogCard = ({ link, imgUrl, title, tags, description, updatedAt }) => {
    return (
        <Card sx={{ maxWidth: 280, width: 280, minHeight: 460 }} className={'lift lift-lg h-100 bg-dark-300 border-radius-5 '} raised={true} >
            <CardActionArea href={link} className="h-100">
                <CardMedia
                    component='img'
                    height='200'
                    image={imgUrl}
                    alt={title}
                />
                <CardContent className='h-100 bg-dark-300 bg-dark-primary-gradient' style={{ minHeight: 260 }}>
                    <Stack direction='row' spacing={1} className={"pt-1"}>
                        {tags.map((tag, index) => (
                            <Chip key={index} label={tag} color='primary' variant='outlined' size={"small"} />
                        ))}
                    </Stack>
                    <Typography gutterBottom variant='h6' component='div' sx={{ textTransform: 'capitalize', fontWeight: 700, pt: 1, pb: 0, fontSize: '1rem' }}>
                        {title}
                    </Typography>
                    <Typography gutterBottom variant='body1' color='text.secondary' sx={{ textTransform: 'capitalize', fontSize: 10, py: 1 }} >
                        {updatedAt ? moment(updatedAt).format('MMMM Do YYYY') : ''}
                    </Typography>
                    <Typography gutterBottom variant='body2' color='text.secondary' sx={{ pb: 0 }}>
                        {description}...
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

BlogCard.defaultProps = {
    imgUrl: '#',
    title: 'Title',
    description: '...',
    tags: [],
    link: '#',
    updatedAt: moment().format('MMMM Do, YYYY'),
};

BlogCard.propTypes = {
    imgUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    link: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
};



export default BlogCard;
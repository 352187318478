// URL SHORTENER
export const GET_SHORT_URL_REQUEST = "GET_SHORT_URL_REQUEST";
export const GET_SHORT_URL_SUCCESS = "GET_SHORT_URL_SUCCESS";
export const GET_SHORT_URL_FAILURE = "GET_SHORT_URL_FAILURE";

export const CREATE_SHORT_URL_REQUEST = "CREATE_SHORT_URL_REQUEST";
export const CREATE_SHORT_URL_SUCCESS = "CREATE_SHORT_URL_SUCCESS";
export const CREATE_SHORT_URL_FAILURE = "CREATE_SHORT_URL_FAILURE";

export const UPDATE_SHORT_URL_REQUEST = "UPDATE_SHORT_URL_REQUEST";
export const UPDATE_SHORT_URL_SUCCESS = "UPDATE_SHORT_URL_SUCCESS";
export const UPDATE_SHORT_URL_FAILURE = "UPDATE_SHORT_URL_FAILURE";

export const DELETE_SHORT_URL_REQUEST = "DELETE_SHORT_URL_REQUEST";
export const DELETE_SHORT_URL_SUCCESS = "DELETE_SHORT_URL_SUCCESS";
export const DELETE_SHORT_URL_FAILURE = "DELETE_SHORT_URL_FAILURE";

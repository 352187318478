import React from "react";
import { render } from "Utils/react-utils";
import { Provider } from "react-redux";
import store from "../../store";
import RegisterDapp from "Components/landing/home/RegisterDapp";
import LandingMenu from "Components/landing/home/LandingMenu";
import Landing from "Components/landing/home/Landing";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { theme } from "../../context/theme";

const initPage = async () => {
	const landingNav = document.querySelector("#landing-nav");
	const enterDapp = document.querySelector("#register-dapp");
	const landingApp = document.querySelector("#landingApp");

	if (landingNav) {
		render(
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<StyledEngineProvider injectFirst>
						<LandingMenu />
					</StyledEngineProvider>
				</ThemeProvider>
			</Provider>,
			landingNav,
		);
	}
	if (enterDapp) {
		render(
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<StyledEngineProvider injectFirst>
						<RegisterDapp textContent={"Register"} isLogin={false} />
					</StyledEngineProvider>
				</ThemeProvider>
			</Provider>,
			enterDapp,
		);
	}
	if (landingApp) {
		render(
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<StyledEngineProvider injectFirst>
						<Landing />
					</StyledEngineProvider>
				</ThemeProvider>
			</Provider>,
			landingApp,
		);
	}
};

// export default initPage;
initPage();

//ARTICLES
export const GET_ARTICLES_REQUEST = "GET_ARTICLES_REQUEST";
export const GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS";
export const GET_ARTICLES_FAILURE = "GET_ARTICLES_FAILURE";
export const GET_ARTICLE_REQUEST = "GET_ARTICLE_REQUEST";
export const GET_ARTICLE_SUCCESS = "GET_ARTICLE_SUCCESS";
export const GET_ARTICLE_FAILURE = "GET_ARTICLE_FAILURE";
export const UPDATE_ARTICLE_REQUEST = "UPDATE_ARTICLE_REQUEST";
export const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";
export const UPDATE_ARTICLE_FAILURE = "UPDATE_ARTICLE_FAILURE";
export const UPDATE_ARTICLE_IMAGE_REQUEST = "UPDATE_ARTICLE_IMAGE_REQUEST";
export const UPDATE_ARTICLE_IMAGE_SUCCESS = "UPDATE_ARTICLE_IMAGE_SUCCESS";
export const UPDATE_ARTICLE_IMAGE_FAILURE = "UPDATE_ARTICLE_IMAGE_FAILURE";
export const CREATE_BOOKMARK_ARTICLE_REQUEST = "CREATE_BOOKMARK_ARTICLE_REQUEST";
export const CREATE_BOOKMARK_ARTICLE_SUCCESS = "CREATE_BOOKMARK_ARTICLE_SUCCESS";
export const CREATE_BOOKMARK_ARTICLE_FAILURE = "CREATE_BOOKMARK_ARTICLE_FAILURE";
export const DELETE_BOOKMARK_ARTICLE_REQUEST = "DELETE_BOOKMARK_ARTICLE_REQUEST";
export const DELETE_BOOKMARK_ARTICLE_SUCCESS = "DELETE_BOOKMARK_ARTICLE_SUCCESS";
export const DELETE_BOOKMARK_ARTICLE_FAILURE = "DELETE_BOOKMARK_ARTICLE_FAILURE";

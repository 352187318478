import type { Reducer } from "@reduxjs/toolkit";
import { Toast } from "react-bootstrap";
import { SHOW_TOAST_LOADING, GET_TOAST, GET_TOASTS, DELETE_TOAST, DELETE_TOASTS, CREATE_TOAST, ERROR_TOASTS } from "../actions/types/types";

const SaxonData = window.SaxonData;

type Toast = {
	id: string;
	timestamp: NumStr;
	titleText: string;
	bodyText: string;
	iconMdi: string;
	alignmentLeft: boolean;
	bg?: string;
	color?: string;
};
type Toasts = Toast[];

type IState = {
	toasts: Toasts;
	loading: boolean;
	error: boolean;
};

const initialState: IState = {
	toasts: [],
	loading: false,
	error: false,
};

export const toastReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case SHOW_TOAST_LOADING:
			return {
				...state,
				loading: true,
			};
		case GET_TOAST:
			return {
				...state,
				loading: false,
				...payload,
			};
		case GET_TOASTS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case DELETE_TOAST:
			return {
				...state,
				loading: false,
				...payload,
			};
		case DELETE_TOASTS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case CREATE_TOAST:
			return {
				...state,
				loading: false,
				...payload,
			};
		case ERROR_TOASTS:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
};

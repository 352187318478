import React, { forwardRef } from "react";
import ButtonUnstyled, { buttonUnstyledClasses, ButtonUnstyledProps } from "@mui/base/ButtonUnstyled";
import { styled } from "@mui/system";
import CSS from "csstype";
import Link from "@mui/material/Link";

interface IButtonProps {
	size: "small" | "large" | "xlarge" | "xxlarge";
	children: React.ReactNode;
	[key: string]: any;
}

const ButtonRoot = forwardRef(function ButtonRoot(props: IButtonProps, ref: React.LegacyRef<SVGSVGElement>): JSX.Element {
	const { children, size, ...other } = props;

	const fs = {
		small: "12px",
		large: "14px",
		xlarge: "16px",
		xxlarge: "18px",
	};

	const defaultStyles: CSS.Properties = {
		fontFamily: "inherit",
		fontSize: size ? fs[size] : "14px",
		fontWeight: 500,
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		textTransform: "uppercase",
	};

	const contentStyles = {
		...defaultStyles,
	};

	const divProps = {
		style: contentStyles,
		className: "btn-content",
	};

	switch (size) {
		case "small":
			return (
				<svg width="100" height="30" {...other} ref={ref}>
					<polygon points="0,30 0,0 100,0 100,30" className="bg" />
					<polygon points="0,30 0,0 100,0 100,30" className="borderEffect-sm" />
					<foreignObject x="0" y="0" width="100" height="30">
						<div {...divProps}>{children}</div>
					</foreignObject>
				</svg>
			);
		case "large":
			return (
				<svg width="150" height="40" {...other} ref={ref}>
					<polygon points="0,40 0,0 150,0 150,40" className="bg round" />
					<polygon points="0,40 0,0 150,0 150,40" className="borderEffect-lg round" />
					<foreignObject x="0" y="0" width="150" height="40">
						<div {...divProps}>{children}</div>
					</foreignObject>
				</svg>
			);
		case "xlarge":
			return (
				<svg width="300" height="50" {...other} ref={ref}>
					<polygon points="0,50 0,0 300,0 300,50" className="bg" />
					<polygon points="0,50 0,0 300,0 300,50" className="borderEffect-xl" />
					<foreignObject x="0" y="0" width="300" height="50">
						<div {...divProps}>{children}</div>
					</foreignObject>
				</svg>
			);
		case "xxlarge":
			return (
				<svg width="342" height="50" {...other} ref={ref}>
					<polygon points="0,50 0,0 342,0 342,50" className="bg" />
					<polygon points="0,50 0,0 342,0 342,50" className="borderEffect-xxl" />
					<foreignObject x="0" y="0" width="342" height="50">
						<div {...divProps}>{children}</div>
					</foreignObject>
				</svg>
			);
		default:
			return (
				<svg width="150" height="40" {...other} ref={ref}>
					<polygon points="0,40 0,0 150,0 150,40" className="bg round" />
					<polygon points="0,40 0,0 150,0 150,40" className="borderEffect-lg round" />
					<foreignObject x="0" y="0" width="150" height="40">
						<div {...divProps}>{children}</div>
					</foreignObject>
				</svg>
			);
	}
});

const CustomButtonRoot = styled(ButtonRoot)(
	({ theme }) => `
  overflow: visible;  
  cursor: pointer;
  --main-color: ${theme.palette.mode === "light" ? "rgb(25,118,210)" : "rgb(144,202,249)"};
  --hover-color: ${theme.palette.mode === "light" ? "rgba(25,118,210,0.04)" : "rgba(144,202,249,0.08)"};
  --active-color: ${theme.palette.mode === "light" ? "rgba(25,118,210,0.12)" : "rgba(144,202,249,0.24)"};
  &.round{
    stroke-linejoin: round;
  }
   
  & polygon {
    fill: transparent;
    transition: all 800ms ease;
    pointer-events: none;
  }
  
  & .bg {
    stroke: var(--main-color);
    stroke-width: 0.5;
    filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.1));
    fill: transparent;
  }

  & .borderEffect {
    stroke: var(--main-color);
    stroke-width: 2;
    stroke-dasharray: 150 600;
    stroke-dashoffset: 150;
    fill: transparent;
  }
  & .borderEffect-sm {
    stroke: var(--main-color);
    stroke-width: 2;
    stroke-dasharray: 75 300;
    stroke-dashoffset: 75;
    fill: transparent;
  }
  & .borderEffect-lg {
    stroke: var(--main-color);
    stroke-width: 2;
    stroke-dasharray: 150 600;
    stroke-dashoffset: 150;
    fill: transparent;
  }
  
  & .borderEffect-xl {
    stroke: var(--main-color);
    stroke-width: 2;
    stroke-dasharray: 375 1500;
    stroke-dashoffset: 375;
    fill: transparent;
  }
  & .borderEffect-xxl {
    stroke: var(--main-color);
    stroke-width: 2;
    stroke-dasharray: 428 1710;
    stroke-dashoffset: 428;
    fill: transparent;
  }

  &:hover,
  &.${buttonUnstyledClasses.focusVisible} {
    .borderEffect-sm {
      stroke-dashoffset: -300;
    }
    .borderEffect-lg {
      stroke-dashoffset: -600;
    }
    .borderEffect-xl {
      stroke-dashoffset: -1500;
    }
    .borderEffect-xxl {
      stroke-dashoffset: -1710;
    }
    

    .bg {
      fill: var(--hover-color);
    }
  }

  &:focus,
  &.${buttonUnstyledClasses.focusVisible} {
    outline: none;
  }

  &.${buttonUnstyledClasses.active} { 
    & .bg {
      fill: var(--active-color);
      transition: fill 300ms ease-out;
    }
  }

  & foreignObject {
    pointer-events: none;

    & .btn-content {
      font-family:inherit;
      font-size: 14px;
      font-weight: 400;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--main-color) !important;
      text-transform: uppercase;
      
    }

   
  }
   & svg {
      margin: 0 5px;
    }
  `,
);
interface SvgButtonProps extends ButtonUnstyledProps {
	size?: "small" | "large" | "xlarge" | "xxlarge";
}
const SvgButton = React.forwardRef(function SvgButton(props: SvgButtonProps, ref: React.ForwardedRef<any>) {
	return <ButtonUnstyled {...props} component={CustomButtonRoot} ref={ref} />;
});

interface IProps extends SvgButtonProps {
	children: React.ReactNode;
	href: string;
	size?: "small" | "large" | "xlarge" | "xxlarge";
}

export default function SnazzyButton({ children, href, ...other }: IProps) {
	return (
		<Link href={href} underline="none">
			<SvgButton {...other}>{children}</SvgButton>
		</Link>
	);
}

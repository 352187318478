import type { Reducer } from "@reduxjs/toolkit";
import type { OTCContractWallet, PPContractWallet } from "Actions/adminWalletActions";
import type { UserWallets } from "App/src/types/api/Portfolio";

import {
	GET_OTC_CONTRACT_WALLET_REQUEST,
	GET_OTC_CONTRACT_WALLET_SUCCESS,
	GET_OTC_CONTRACT_WALLET_FAILURE,
	GET_PP_CONTRACT_WALLET_REQUEST,
	GET_PP_CONTRACT_WALLET_SUCCESS,
	GET_PP_CONTRACT_WALLET_FAILURE,
	GET_USERS_WALLETS_REQUEST,
	GET_USERS_WALLETS_SUCCESS,
	GET_USERS_WALLETS_ERROR,
} from "../actions/types/types";

// TO DO
// these types need work

interface IState {
	loading: boolean;
	error: string;
	OTC: {
		walletList: {
			[walletAddress: string]: PPContractWallet;
		};
		currentWallet: OTCContractWallet;
	};
	PP: {
		walletList: {
			[walletAddress: string]: PPContractWallet;
		};
		currentWallet: PPContractWallet;
	};
	usersWallets: UserWallets;
}

const initialState: IState = {
	loading: true,
	error: null,
	PP: {
		walletList: {},
		currentWallet: null,
	},
	OTC: {
		walletList: {},
		currentWallet: null,
	},
	usersWallets: [],
};

export const adminWalletReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_USERS_WALLETS_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_USERS_WALLETS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_USERS_WALLETS_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case GET_OTC_CONTRACT_WALLET_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_OTC_CONTRACT_WALLET_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_OTC_CONTRACT_WALLET_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case GET_PP_CONTRACT_WALLET_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_PP_CONTRACT_WALLET_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_PP_CONTRACT_WALLET_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
};

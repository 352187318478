import React from 'react';
import PropTypes from 'prop-types';
import BlogCard from './BlogCard';
import Flickity from 'react-flickity-component';


const flickityOptions = {
    initialIndex: 1,
    pageDots: true,
    cellAlign: "center",
    wrapAround: true,
    imagesLoaded: false,
    percentPosition: true,
    draggable: true,

};

const BlogPosts = ({ articles }) => {
    const articleToCardProps = (article) => {
        const { title, subtitle, tags, slug, image, updatedAt } = article;
        const result = {
            link: `/blog/${slug}`,
            imgUrl: image,
            title: title,
            tags: tags.slice(0, 1),
            description: subtitle,
            updatedAt: updatedAt,
        };
        return result;
    };
    return (
        <div className="container-fluid w-100 px-0">

            <Flickity
                className='flickity-button-inset' // default ''
                elementType={'div'} // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
            >
                {articles.map((article) => (
                    <div key={article.id} className="col-12 col-sm-6 col-md-4 px-2 py-1 d-flex justify-content-center">
                        <BlogCard  {...articleToCardProps(article)} />
                    </div>
                ))}
            </Flickity>




        </div>
    );
};

BlogPosts.defaultProps = {
    articles: window?.SaxonData?.articles ? window.SaxonData.articles : [],
};

BlogPosts.propTypes = {
    articles: PropTypes.array.isRequired
};

export default BlogPosts;
import type { Reducer } from "@reduxjs/toolkit";
import type { Assets, Transaction } from "App/src/types/pages/Portfolio";
import { GET_PORTFOLIO_REQUEST, GET_PORTFOLIO_SUCCESS, GET_PORTFOLIO_FAILURE, GET_TRANSACTIONS_REQUEST, GET_TRANSACTIONS_SUCCESS, GET_TRANSACTIONS_FAILURE } from "../actions/types/types";
import { bn } from "Utils/utils";

interface IState {
	loading: boolean;
	error: boolean;
	assets: Assets;
	assetsWithPrice: Assets;
	totalValue: BN;
	nativeValue: BN;
	tokenValue: BN;
	assetsCount: BN;
	transactions: Transaction[];
}

const initialState: IState = {
	loading: true,
	error: false,
	assets: [],
	assetsWithPrice: [],
	transactions: [],
	totalValue: bn(0),
	nativeValue: bn(0),
	tokenValue: bn(0),
	assetsCount: bn(0),
};

export const portfolioReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_PORTFOLIO_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_PORTFOLIO_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case GET_PORTFOLIO_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case GET_TRANSACTIONS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_TRANSACTIONS_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case GET_TRANSACTIONS_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
};

//OTC
export const GET_OTC_TERMS = "GET_OTC_TERMS";
export const GET_OTC_TERM = "GET_OTC_TERM";
export const CREATE_OTC_TERM = "CREATE_OTC_TERM";
export const UPDATE_OTC_TERM = "UPDATE_OTC_TERM";
export const DELETE_OTC_TERM = "DELETE_OTC_TERM";
export const GET_OTC_TERM_TRANCHES = "GET_OTC_TERM_TRANCHES";
export const GET_OTC_TERM_TRANCHE = "GET_OTC_TERM_TRANCHE";
export const CREATE_OTC_TERM_TRANCHE = "CREATE_OTC_TERM_TRANCHE";
export const UPDATE_OTC_TERM_TRANCHE = "UPDATE_OTC_TERM_TRANCHE";
export const DELETE_OTC_TERM_TRANCHE = "DELETE_OTC_TERM_TRANCHE";
export const DELETE_OTC_TERM_TRANCHES = "DELETE_OTC_TERM_TRANCHES";
export const OTC_ERROR = "OTC_ERROR";
export const ADMIN_ERROR = "ADMIN_ERROR";
export const SHOW_SIDEBAR_LOADER = "SHOW_SIDEBAR_LOADER";
export const SHOW_WIDGET_LOADER = "SHOW_WIDGET_LOADER";
export const SHOW_OTC_LOADER = "SHOW_OTC_LOADER";
export const GET_OTC_CONTRACT_BALANCES_REQUEST = "GET_OTC_CONTRACT_BALANCES_REQUEST";
export const GET_OTC_CONTRACT_BALANCES_SUCCESS = "GET_OTC_CONTRACT_BALANCES_SUCCESS";
export const GET_OTC_CONTRACT_BALANCES_FAILURE = "GET_OTC_CONTRACT_BALANCES_FAILURE";

export const GET_OTC_CONTRACT_WALLET_REQUEST = "GET_OTC_CONTRACT_WALLET_REQUEST";
export const GET_OTC_CONTRACT_WALLET_SUCCESS = "GET_OTC_CONTRACT_WALLET_SUCCESS";
export const GET_OTC_CONTRACT_WALLET_FAILURE = "GET_OTC_CONTRACT_WALLET_FAILURE";

export const GET_PP_CONTRACT_WALLET_REQUEST = "GET_PP_CONTRACT_WALLET_REQUEST";
export const GET_PP_CONTRACT_WALLET_SUCCESS = "GET_PP_CONTRACT_WALLET_SUCCESS";
export const GET_PP_CONTRACT_WALLET_FAILURE = "GET_PP_CONTRACT_WALLET_FAILURE";

export const GET_USERS_WALLETS_REQUEST = "GET_PP_USERS_WALLETS_REQUEST";
export const GET_USERS_WALLETS_SUCCESS = "GET_PP_USERS_WALLETS_SUCCESS";
export const GET_USERS_WALLETS_ERROR = "GET_PP_USERS_WALLETS_ERROR";

import { config } from "../../config/config";
import type { Reducer } from "@reduxjs/toolkit";
import blockies from "blockies";
import type { TokenBalancesResult } from "Utils/etherscan";

import { GET_TOKEN_ALLOWANCE, TOKEN_ALLOWANCE_ERROR, SET_TOKEN_ALLOWANCE, SET_TOKEN_ALLOWANCE_ERROR, GET_WALLET_BALANCES, ERROR_WALLET_BALANCES } from "../actions/types/types";

// TO DO
// these types need work
type Allowance = {
	sellTokenAddress: string;
	contractAddress: string;
	allowance: string;
	approved: boolean;
	balance: string;
};

interface IState {
	loading: boolean;
	allowances: Allowance[];
	approvalTx: string;
	error: boolean;
	isConnected: boolean;
	userWalletInfo: {
		address: string;
		addressShort: string;
		blockchain: string;
		network: string;
		chainId: typeof config.ETHEREUM_CHAIN_ID;
		explorer: string;
		themeColor: string;
		isConnected: boolean;
		networkIcon: string;
		networkIconWhite: string;
		blockiesIcon: string;
	};
	balances: TokenBalancesResult;
}

const initialState: IState = {
	loading: true,
	allowances: [],
	approvalTx: null,
	error: false,
	isConnected: !!window.userAddress,
	userWalletInfo: {
		address: window?.userAddress || "",
		addressShort: window.userAddress ? window?.userAddress.slice(0, 10) + "..." + window.userAddress.slice(window.userAddress.length - 5, window.userAddress.length) : "",
		blockchain: config.BLOCKCHAIN_NAME,
		network: config.ETHEREUM_NETWORK,
		chainId: config.ETHEREUM_CHAIN_ID,
		explorer: config.ETHERSCAN_ADDRESS_URL + window?.userAddress,
		themeColor: config.WALLET_NETWORK.themeColor,
		isConnected: !!window.userAddress,
		networkIcon: config.WALLET_NETWORK.iconUrl,
		networkIconWhite: config.WALLET_NETWORK.iconUrlWhite,
		blockiesIcon: window.userAddress
			? blockies({
					// All options are optional
					seed: window.userAddress, // seed used to generate icon data, default: random
					color: "#0097fc", // to manually specify the icon color, default: random
					bgcolor: "#ff679b", // choose a different background color, default: random
					size: 8, // width/height of the icon in blocks, default: 8
					scale: 3, // width/height of each block in pixels, default: 4
					spotcolor: "#52e3c2", // each pixel has a 13% chance of being of a third color,
					// default: random. Set to -1 to disable it. These "spots" create structures
					// that look like eyes, mouths and noses.
			  }).toDataURL()
			: null,
	},
	balances: null,
};

export const walletReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_TOKEN_ALLOWANCE:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case TOKEN_ALLOWANCE_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case SET_TOKEN_ALLOWANCE:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_WALLET_BALANCES:
			return {
				...state,
				loading: false,
				...payload,
			};
		case ERROR_WALLET_BALANCES:
			return {
				...state,
				loading: false,
				error: false,
			};
		case SET_TOKEN_ALLOWANCE_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
};

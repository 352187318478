import {
	GET_ARTICLES_REQUEST,
	GET_ARTICLES_SUCCESS,
	GET_ARTICLES_FAILURE,
	GET_ARTICLE_REQUEST,
	GET_ARTICLE_SUCCESS,
	GET_ARTICLE_FAILURE,
	UPDATE_ARTICLE_REQUEST,
	UPDATE_ARTICLE_SUCCESS,
	UPDATE_ARTICLE_FAILURE,
	UPDATE_ARTICLE_IMAGE_REQUEST,
	UPDATE_ARTICLE_IMAGE_SUCCESS,
	UPDATE_ARTICLE_IMAGE_FAILURE,
	CREATE_BOOKMARK_ARTICLE_REQUEST,
	CREATE_BOOKMARK_ARTICLE_SUCCESS,
	CREATE_BOOKMARK_ARTICLE_FAILURE,
	DELETE_BOOKMARK_ARTICLE_REQUEST,
	DELETE_BOOKMARK_ARTICLE_SUCCESS,
	DELETE_BOOKMARK_ARTICLE_FAILURE,
} from "../actions/types/types";

const SaxonData = window?.SaxonData;
const article = SaxonData ? SaxonData.article : {};

const initialState = {
	articles: [],
	article: article,
	loading: true,
	error: false,
};

export const articlesReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_ARTICLES_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_ARTICLES_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case GET_ARTICLES_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case GET_ARTICLE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_ARTICLE_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case GET_ARTICLE_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case UPDATE_ARTICLE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case UPDATE_ARTICLE_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case UPDATE_ARTICLE_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case UPDATE_ARTICLE_IMAGE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case UPDATE_ARTICLE_IMAGE_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case UPDATE_ARTICLE_IMAGE_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case CREATE_BOOKMARK_ARTICLE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CREATE_BOOKMARK_ARTICLE_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case CREATE_BOOKMARK_ARTICLE_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case DELETE_BOOKMARK_ARTICLE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case DELETE_BOOKMARK_ARTICLE_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case DELETE_BOOKMARK_ARTICLE_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
};

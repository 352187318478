import type { Reducer } from "@reduxjs/toolkit";
import { GET_DEALS_FAILURE, GET_DEALS_REQUEST, GET_DEALS_SUCCESS, GET_DEAL_FAILURE, GET_DEAL_REQUEST, GET_DEAL_SUCCESS } from "Actions/types/deal";
import { Company } from "App/src/types/api/Company";
import { Deal } from "App/src/types/api/Deal";
import { User } from "App/src/types/api/User";

interface IState {
	loading: boolean;
	error: Error;
	companyid?: Company["_id"];
	dealid?: Deal["_id"];
	userid?: User["_id"];
	deals?: Deal<true, true, true, true>[];
	hasOTC: boolean;
}

const initialState: IState = {
	loading: true,
	error: null,
	companyid: window?.SaxonData?.companyid || null,
	dealid: window?.SaxonData?.dealid || null,
	userid: window?.SaxonData?.userid || null,
	deals: window?.SaxonData?.deals || null,
	hasOTC: window?.SaxonData?.deal?.hasOTC || false,
};

export const dealReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_DEALS_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_DEALS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_DEALS_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case GET_DEAL_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_DEAL_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_DEAL_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		default:
			return state;
	}
};

import * as React from "react";
import Carousel from "react-bootstrap/Carousel";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

export default function Testimonials() {
  const nextIcon = (
    <ChevronRightRoundedIcon aria-hidden="true" className="d-none d-lg-block fs-1 rounded-circle" />
  );
  const prevIcon = (
    <ChevronLeftRoundedIcon aria-hidden="true" className=" fs-1 d-none d-lg-block rounded-circle" />
  );
  return (
    <div>
      <Carousel
        fade={true}
        indicators={true}
        interval={null}
        nextIcon={nextIcon}
        prevIcon={prevIcon}>
        <Carousel.Item className="my-3">
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="card px-0 bg-dark-300 shadow-none border-radius-2"
              style={{ width: 800 }}>
              <div className="card-body">
                <div className="container-fluid h-100 d-flex align-self-center">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-12 d-flex align-items-center justify-content-center px-3 ">
                      <div className="w-100 d-flex align-items-center justify-content-center">
                        <img
                          className="img-fluid mb-3 mb-lg-0 mb-xl-0 align-self-center mx-auto"
                          src="https://saxoncircle.blob.core.windows.net/home-page/Minima_test.svg"
                          style={{ width: 300, height: 300 }}
                          alt="Minima"></img>
                      </div>
                    </div>
                    <div className="col-xl-8  col-lg-8  col-md-8 col-12 d-flex align-items-center justify-content-center px-0 px-sm-0 px-md-3">
                      <div className="text-snow font-weight-light px-0 px-sm-0 px-md-3">
                        <div className="fs-3 text-info font-weight-light d-none d-sm-none d-md-block py-2">
                          Minima
                        </div>
                        <figure>
                          <blockquote className="blockquote fw-200-inter mt-3 mt-sm-3 fs-5">
                            <p>
                              "Saxon Advisors provided great support in our fundraising efforts.
                              They are both meticulous and flexible in their approach, which has
                              been invaluable as we navigated these unchartered waters."
                            </p>
                          </blockquote>
                          <div className="pb-1">
                            <span className="fw-500-inter fs-5">Chief Executive Officer</span>
                            <br />
                            <span className="fw-200-inter fs-5"> Hugo Feiler</span>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className="my-3">
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="card px-0 bg-dark-300 shadow-none border-radius-2"
              style={{ width: 800 }}>
              <div className="card-body">
                <div className="container-fluid h-100 d-flex align-self-center">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4  col-md-4  col-12  d-flex align-items-center justify-content-center px-3">
                      <img
                        className="img-fluid mb-3  mb-lg-0 mb-xl-0 "
                        src="https://saxoncircle.blob.core.windows.net/home-page/iTrust_test.svg"
                        style={{ width: 300, height: 300 }}
                        alt="iTrust"></img>
                    </div>

                    <div className="col-xl-8  col-lg-8  col-md-8 col-12 d-flex align-items-center justify-content-center px-0 px-sm-0 px-md-3">
                      <div className="text-snow font-weight-light px-0 px-sm-0 px-md-3">
                        <div className="fs-3 text-info d-none d-sm-none d-md-block d-lg-block d-xl-block font-weight-light py-2">
                          iTrust
                        </div>
                        <figure>
                          <blockquote className="blockquote fw-200-inter mt-3 mt-sm-3 fs-5">
                            <p>
                              "The Saxon team were incredibly professional and helpful beyond our
                              requirements. Since completing the round we have already recommended
                              them to several other projects in our network."
                            </p>
                          </blockquote>
                          <div className="pb-1">
                            <span className="fw-500-inter fs-5">Founder</span>
                            <br />
                            <span className="fw-200-inter fs-5">Pseudonymous</span>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className="my-3">
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="card px-0 bg-dark-300 shadow-none border-radius-2"
              style={{ width: 800 }}>
              <div className="card-body">
                <div className="container-fluid h-100 d-flex align-self-center">
                  <div className="row ">
                    <div className="col-xl-4 col-lg-4  col-md-4  col-12  d-flex align-items-center justify-content-center px-3">
                      <img
                        className="img-fluid mb-3  mb-lg-0 mb-xl-0 "
                        src="https://saxoncircle.blob.core.windows.net/home-page/CasperLabs_test.svg"
                        style={{ width: 300, height: 300 }}
                        alt="Casper Labs"></img>
                    </div>

                    <div className="col-xl-8  col-lg-8  col-md-8 col-12 d-flex align-items-center justify-content-center px-0 px-sm-0 px-md-3">
                      <div className="text-snow font-weight-light px-0 px-sm-0 px-md-3">
                        <div className="fs-3 text-info font-weight-light d-none d-sm-none d-md-block py-2">
                          Casper Labs
                        </div>

                        <figure>
                          <blockquote className="blockquote fw-200-inter mt-3 mt-sm-3 fs-5">
                            <p>
                              "We can't thank everyone at Saxon Advisors enough for their support
                              and contributions to CasperLabs. If anyone wants to know about our
                              experience working with Saxon, please contact me any time."
                            </p>
                          </blockquote>
                          <div className="pb-1">
                            <span className="fw-500-inter fs-5">Chief Operating Officer</span>
                            <br />
                            <span className="fw-200-inter fs-5">Clifford Chance</span>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

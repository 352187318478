import { combineReducers } from "@reduxjs/toolkit";
import { adminWalletReducer } from "./adminWalletReducers";
import { adminDealReducer } from "./adminDealReducers";
import { adminTearsheetReducer } from "./adminTearsheetReducers";
import { coffeehouseReducer } from "./coffeehouseReducers";
import { swapsReducer } from "./swapsReducers";
import { otcReducer } from "./otcReducers";
import { coinReducer } from "./coinReducers";
import { chartsReducer } from "./chartsReducers";
import { walletReducer } from "./walletReducers";
import { privatePlacementsReducer } from "./privatePlacementsReducers";
import { notificationsReducer } from "./notificationsReducers";
import { toastReducer } from "./toastReducers";
import { verificationReducer } from "./verificationReducers";
import { userReducer } from "./userReducers";
import { organisationReducer } from "./organisationReducers";
import { mentionsReducer } from "./mentionsReducers";
import { articlesReducer } from "./articlesReducers";
import { dashboardsReducer } from "./dashboardReducers";
import { saxonMoralisReducer } from "./saxonMoralisReducers";
import { marketsReducer } from "./marketsReducers";
import { portfolioReducer } from "./portfolioReducers";
import { adminCompanyReducer } from "./adminCompanyReducers";
import { dealReducer } from "./dealReducers";
import { tearsheetReducer } from "./tearsheetReducers";
import { shortUrlReducer } from "./shortUrlReducers";

const rootReducer = combineReducers({
	AdminCompanies: adminCompanyReducer,
	AdminDeals: adminDealReducer,
	AdminTearsheet: adminTearsheetReducer,
	AdminWallet: adminWalletReducer,
	Articles: articlesReducer,
	CoffeeHouse: coffeehouseReducer,
	Charts: chartsReducer,
	Coins: coinReducer,
	Dashboards: dashboardsReducer,
	Deals: dealReducer,
	Markets: marketsReducer,
	Mentions: mentionsReducer,
	Notifications: notificationsReducer,
	Organisation: organisationReducer,
	OTC: otcReducer,
	PrivatePlacements: privatePlacementsReducer,
	Portfolio: portfolioReducer,
	SaxonMoralis: saxonMoralisReducer,
	ShortUrl: shortUrlReducer,
	Swaps: swapsReducer,
	Tearsheets: tearsheetReducer,
	Toasts: toastReducer,
	User: userReducer,
	Verification: verificationReducer,
	Wallets: walletReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

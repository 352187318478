import { GET_MENTIONS_REQUEST, GET_MENTIONS_SUCCESS, GET_MENTIONS_FAILURE } from "../actions/types/types";
import type { Reducer } from "@reduxjs/toolkit";
const initialState = {
	loading: true,
	error: false,
};

export const mentionsReducer: Reducer<typeof initialState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_MENTIONS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_MENTIONS_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case GET_MENTIONS_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
};

import { Notification } from "App/src/types/api/Notification";
import { NOTIFICATION_ERROR, GET_NOTIFICATIONS, NOTIFICATIONS_ERROR, READ_NOTIFICATION, SHOW_NOTIFICATIONS_LOADING, NOTIFICATIONS_LOADING_ERROR } from "../actions/types/types";
import type { Reducer } from "@reduxjs/toolkit";

type IState = {
	notifications: Notification[];
	loading: boolean;
	error: Error | null;
};

const initialState: IState = {
	notifications: [],
	loading: true,
	error: null,
};

export const notificationsReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case SHOW_NOTIFICATIONS_LOADING:
			return {
				...state,
				loading: true,
			};
		case GET_NOTIFICATIONS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case READ_NOTIFICATION:
			return {
				...state,
				loading: false,
				...payload,
			};
		case NOTIFICATIONS_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case NOTIFICATION_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case NOTIFICATIONS_LOADING_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
};

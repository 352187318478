import type { Reducer } from "@reduxjs/toolkit";

import {
	GET_ADMIN_TEARSHEETS_FAILURE,
	GET_ADMIN_TEARSHEETS_REQUEST,
	GET_ADMIN_TEARSHEETS_SUCCESS,
	GET_ADMIN_TEARSHEET_FAILURE,
	GET_ADMIN_TEARSHEET_REQUEST,
	GET_ADMIN_TEARSHEET_SUCCESS,
	CREATE_ADMIN_TEARSHEET_FAILURE,
	CREATE_ADMIN_TEARSHEET_REQUEST,
	CREATE_ADMIN_TEARSHEET_SUCCESS,
	DELETE_ADMIN_TEARSHEET_FAILURE,
	DELETE_ADMIN_TEARSHEET_REQUEST,
	DELETE_ADMIN_TEARSHEET_SUCCESS,
	UPDATE_ADMIN_TEARSHEET_FAILURE,
	UPDATE_ADMIN_TEARSHEET_REQUEST,
	UPDATE_ADMIN_TEARSHEET_SUCCESS,
} from "Actions/types/adminTearsheet";
import { Tearsheet } from "App/src/types/api/Tearsheet";

interface IState {
	loading: boolean;
	error: string;
	tearsheet: Tearsheet;
	tearsheets: Tearsheet[];
	companyid: typeof window.SaxonData.admin.tearsheet.companyid;
	dealid: typeof window.SaxonData.admin.tearsheet.dealid;
	countries: typeof window.SaxonData.admin.tearsheet.countries;
	form: typeof window.SaxonData.admin.tearsheet.form;
}

const initialState: IState = {
	loading: true,
	error: null,
	tearsheet: window?.SaxonData?.admin?.tearsheet?.tearsheet || null,
	tearsheets: [],
	companyid: window?.SaxonData?.admin?.tearsheet?.companyid,
	dealid: window?.SaxonData?.admin?.tearsheet?.dealid,
	countries: window?.SaxonData?.admin?.tearsheet?.countries,
	form: window?.SaxonData?.admin?.tearsheet?.form,
};

export const adminTearsheetReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_ADMIN_TEARSHEETS_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_ADMIN_TEARSHEETS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_ADMIN_TEARSHEETS_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case GET_ADMIN_TEARSHEET_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_ADMIN_TEARSHEET_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_ADMIN_TEARSHEET_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case CREATE_ADMIN_TEARSHEET_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case CREATE_ADMIN_TEARSHEET_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case CREATE_ADMIN_TEARSHEET_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case DELETE_ADMIN_TEARSHEET_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case DELETE_ADMIN_TEARSHEET_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case DELETE_ADMIN_TEARSHEET_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case UPDATE_ADMIN_TEARSHEET_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case UPDATE_ADMIN_TEARSHEET_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case UPDATE_ADMIN_TEARSHEET_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		default:
			return state;
	}
};

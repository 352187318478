import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const SnazzyBurgerMenu = ({ isOpen, onHover, onClick }) => {
  return (
    <div
      className={classnames("menu-btn", isOpen ? "open" : "", "z-index-900", "")}
      aria-label="Toggle Navigation"
      onMouseEnter={onHover ? () => onHover(!isOpen) : null}
      onClick={onClick ? () => onClick(!isOpen) : null}>
      <div className={classnames("menu-btn-burger")}></div>
    </div>
  );
};

SnazzyBurgerMenu.propTypes = {
  isOpen: PropTypes.bool,
  onHover: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

SnazzyBurgerMenu.defaultProps = {
  isOpen: false,
  onHover: false,
  onClick: false,
};

export default SnazzyBurgerMenu;

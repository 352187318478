import { createRoot } from "react-dom/client";

/**
 * Creates a root element for a React tree.
 * @param {JSX.Element} component
 * @param {HTMLElement} element
 * @returns {void}
 */
const render = (component: JSX.Element, element: HTMLElement): void => {
	const root = createRoot(element);
	root.render(component);
};

export { render };

//ORGANISATION
export const GET_ORGANISATION_FAILURE = "GET_ORGANISATION_FAILURE";
export const GET_ORGANISATION_SUCCESS = "GET_ORGANISATION_SUCCESS";
export const GET_ORGANISATION_REQUEST = "GET_ORGANISATION_REQUEST";
export const GET_ORGANISATION_ACCESS_TOKEN_FAILURE = "GET_ORGANISATION_ACCESS_TOKEN_FAILURE";
export const GET_ORGANISATION_ACCESS_TOKEN_SUCCESS = "GET_ORGANISATION_ACCESS_TOKEN_SUCCESS";
export const GET_ORGANISATION_ACCESS_TOKEN_REQUEST = "GET_ORGANISATION_ACCESS_TOKEN_REQUEST";
export const CREATE_ORGANISATION_FAILURE = "CREATE_ORGANISATION_FAILURE";
export const CREATE_ORGANISATION_SUCCESS = "CREATE_ORGANISATION_SUCCESS";
export const CREATE_ORGANISATION_REQUEST = "CREATE_ORGANISATION_REQUEST";
export const UPDATE_ORGANISATION_FAILURE = "UPDATE_ORGANISATION_FAILURE";
export const UPDATE_ORGANISATION_SUCCESS = "UPDATE_ORGANISATION_SUCCESS";
export const UPDATE_ORGANISATION_REQUEST = "UPDATE_ORGANISATION_REQUEST";
export const UPDATE_MEMBER_ROLE_FAILURE = "UPDATE_MEMBER_ROLE_FAILURE";
export const UPDATE_MEMBER_ROLE_SUCCESS = "UPDATE_MEMBER_ROLE_SUCCESS";
export const UPDATE_MEMBER_ROLE_REQUEST = "UPDATE_MEMBER_ROLE_REQUEST";
export const UPLOAD_ORGANISATION_IMAGE_FAILURE = "UPLOAD_ORGANISATION_IMAGE_FAILURE";
export const UPLOAD_ORGANISATION_IMAGE_SUCCESS = "UPLOAD_ORGANISATION_IMAGE_SUCCESS";
export const UPLOAD_ORGANISATION_IMAGE_REQUEST = "UPLOAD_ORGANISATION_IMAGE_REQUEST";
export const SWAP_USER_ORGANISATION_FAILURE = "SWAP_USER_ORGANISATION_FAILURE";
export const SWAP_USER_ORGANISATION_SUCCESS = "SWAP_USER_ORGANISATION_SUCCESS";
export const SWAP_USER_ORGANISATION_REQUEST = "SWAP_USER_ORGANISATION_REQUEST";

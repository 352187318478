import type { Reducer } from "redux";

import {
	CREATE_DASHBOARD_FAVOURITE_FAILURE,
	CREATE_DASHBOARD_FAVOURITE_REQUEST,
	CREATE_DASHBOARD_FAVOURITE_SUCCESS,
	DELETE_DASHBOARD_FAVOURITE_FAILURE,
	DELETE_DASHBOARD_FAVOURITE_REQUEST,
	DELETE_DASHBOARD_FAVOURITE_SUCCESS,
	GET_DASHBOARD_FAVOURITES_FAILURE,
	GET_DASHBOARD_FAVOURITES_REQUEST,
	GET_DASHBOARD_FAVOURITES_SUCCESS,
} from "../actions/types/types";

const SaxonData = window.SaxonData && Object.hasOwn(window.SaxonData, "Dashboards") ? window.SaxonData.Dashboards : {};

interface IDashboard {
	date: string;
	name: string;
	slug: string;
	_id: string;
}

interface IState {
	loading: boolean;
	error: boolean;
	dashboards: IDashboard[];
	isFavourite: boolean;
	name: string;
	slug: string;
	userid: string;
	[key: string]: any;
}

const initialState: IState = {
	loading: true,
	error: false,
	dashboards: SaxonData.dashboards || [],
	isFavourite: SaxonData.isFavourite,
	name: SaxonData.name,
	slug: SaxonData.slug,
	userid: SaxonData.userid,
};

export const dashboardsReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_DASHBOARD_FAVOURITES_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_DASHBOARD_FAVOURITES_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case GET_DASHBOARD_FAVOURITES_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case CREATE_DASHBOARD_FAVOURITE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CREATE_DASHBOARD_FAVOURITE_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case CREATE_DASHBOARD_FAVOURITE_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case DELETE_DASHBOARD_FAVOURITE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case DELETE_DASHBOARD_FAVOURITE_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case DELETE_DASHBOARD_FAVOURITE_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
};

import {
  GET_BLOCK_REQUEST,
  GET_BLOCK_SUCCESS,
  GET_BLOCK_FAILURE,
  GET_DATE_TO_BLOCK_REQUEST,
  GET_DATE_TO_BLOCK_SUCCESS,
  GET_DATE_TO_BLOCK_FAILURE,
  GET_LOGS_BY_ADDRESS_REQUEST,
  GET_LOGS_BY_ADDRESS_SUCCESS,
  GET_LOGS_BY_ADDRESS_FAILURE,
  GET_TRANSACTION_REQUEST,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_FAILURE,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAILURE,
  GET_NATIVE_BALANCE_REQUEST,
  GET_NATIVE_BALANCE_SUCCESS,
  GET_NATIVE_BALANCE_FAILURE,
  GET_TOKEN_BALANCES_REQUEST,
  GET_TOKEN_BALANCES_SUCCESS,
  GET_TOKEN_BALANCES_FAILURE,
  GET_TOKEN_TRANSFERS_REQUEST,
  GET_TOKEN_TRANSFERS_SUCCESS,
  GET_TOKEN_TRANSFERS_FAILURE,
  GET_TOKEN_METADATA_REQUEST,
  GET_TOKEN_METADATA_SUCCESS,
  GET_TOKEN_METADATA_FAILURE,
  GET_TOKEN_METADATA_BY_SYMBOL_REQUEST,
  GET_TOKEN_METADATA_BY_SYMBOL_SUCCESS,
  GET_TOKEN_METADATA_BY_SYMBOL_FAILURE,
  GET_TOKEN_PRICE_REQUEST,
  GET_TOKEN_PRICE_SUCCESS,
  GET_TOKEN_PRICE_FAILURE,
  GET_TOKEN_ADDRESS_TRANSFERS_REQUEST,
  GET_TOKEN_ADDRESS_TRANSFERS_SUCCESS,
  GET_TOKEN_ADDRESS_TRANSFERS_FAILURE,
  GET_TOKEN_ALLOWANCE_REQUEST,
  GET_TOKEN_ALLOWANCE_SUCCESS,
  GET_TOKEN_ALLOWANCE_FAILURE,
  GET_ALL_TOKEN_IDS_REQUEST,
  GET_ALL_TOKEN_IDS_SUCCESS,
  GET_ALL_TOKEN_IDS_FAILURE,
  GET_TOKEN_ID_METADATA_REQUEST,
  GET_TOKEN_ID_METADATA_SUCCESS,
  GET_TOKEN_ID_METADATA_FAILURE,
  GET_TOKEN_ID_OWNERS_REQUEST,
  GET_TOKEN_ID_OWNERS_SUCCESS,
  GET_TOKEN_ID_OWNERS_FAILURE,
  GET_WALLET_TOKEN_ID_TRANSFERS_REQUEST,
  GET_WALLET_TOKEN_ID_TRANSFERS_SUCCESS,
  GET_WALLET_TOKEN_ID_TRANSFERS_FAILURE,
  RESOLVE_DOMAIN_REQUEST,
  RESOLVE_DOMAIN_SUCCESS,
  RESOLVE_DOMAIN_FAILURE,
  RESOLVE_ADDRESS_REQUEST,
  RESOLVE_ADDRESS_SUCCESS,
  RESOLVE_ADDRESS_FAILURE,
  GET_PAIR_RESERVES_REQUEST,
  GET_PAIR_RESERVES_SUCCESS,
  GET_PAIR_RESERVES_FAILURE,
  GET_PAIR_ADDRESS_REQUEST,
  GET_PAIR_ADDRESS_SUCCESS,
  GET_PAIR_ADDRESS_FAILURE,
  GET_NFT_TRANSFERS_REQUEST,
  GET_NFT_TRANSFERS_SUCCESS,
  GET_NFT_TRANSFERS_FAILURE,
  GET_NFTS_REQUEST,
  GET_NFTS_SUCCESS,
  GET_NFTS_FAILURE,
  GET_NFT_REQUEST,
  GET_NFT_SUCCESS,
  GET_NFT_FAILURE,
  GET_NFT_TRANSFERS_BY_BLOCK_REQUEST,
  GET_NFT_TRANSFERS_BY_BLOCK_SUCCESS,
  GET_NFT_TRANSFERS_BY_BLOCK_FAILURE,
  GET_NFT_TRADES_REQUEST,
  GET_NFT_TRADES_SUCCESS,
  GET_NFT_TRADES_FAILURE,
  GET_NFT_TRANSFERS_FROM_TO_BLOCK_REQUEST,
  GET_NFT_TRANSFERS_FROM_TO_BLOCK_SUCCESS,
  GET_NFT_TRANSFERS_FROM_TO_BLOCK_FAILURE,
  GET_CONTRACT_NFT_TRANSFERS_REQUEST,
  GET_CONTRACT_NFT_TRANSFERS_SUCCESS,
  GET_CONTRACT_NFT_TRANSFERS_FAILURE,
  GET_NFT_OWNERS_REQUEST,
  GET_NFT_OWNERS_SUCCESS,
  GET_NFT_OWNERS_FAILURE,
  SEARCH_NFTS_REQUEST,
  SEARCH_NFTS_SUCCESS,
  SEARCH_NFTS_FAILURE,
  GET_NFT_LOWEST_PRICE_REQUEST,
  GET_NFT_LOWEST_PRICE_SUCCESS,
  GET_NFT_LOWEST_PRICE_FAILURE,
  GET_NFT_METADATA_REQUEST,
  GET_NFT_METADATA_SUCCESS,
  GET_NFT_METADATA_FAILURE,
} from "../actions/types/saxonMoralis";



const initialState = {
  block: {
        data: null,
        loading: true,
        error: false,          
    },
  dateToBlock: {
        data: null,
        loading: true,
        error: false,          
    },
  logsByAddress: {
        data: null,
        loading: true,
        error: false,          
    },
  transaction: {
        data: null,
        loading: true,
        error: false,          
    },
  transactions: {
        data: null,
        loading: true,
        error: false,          
    },
  nativeBalance: {
        data: null,
        loading: true,
        error: false,          
    },
  tokenBalances: {
        data: null,
        loading: true,
        error: false,          
    },
  tokenTransfers: {
        data: null,
        loading: true,
        error: false,          
    },
  tokenMetadata: {
        data: null,
        loading: true,
        error: false,          
    },
  tokenMetadataBySymbol: {
        data: null,
        loading: true,
        error: false,          
    },
  tokenPrice: {
        data: null,
        loading: true,
        error: false,          
    },
  tokenAddressTransfers: {
        data: null,
        loading: true,
        error: false,          
    },
  tokenAllowance: {
        data: null,
        loading: true,
        error: false,          
    },
  allTokenIds: {
        data: null,
        loading: true,
        error: false,          
    },
  tokenIdMetadata: {
        data: null,
        loading: true,
        error: false,          
    },
  tokenIdOwners: {
        data: null,
        loading: true,
        error: false,          
    },
  walletTokenIdTransfers: {
        data: null,
        loading: true,
        error: false,          
    },
  resolveDomain: {
        data: null,
        loading: true,
        error: false,          
    },
  resolveAddress: {
        data: null,
        loading: true,
        error: false,          
    },
  pairReserves: {
        data: null,
        loading: true,
        error: false,          
    },
  pairAddress: {
        data: null,
        loading: true,
        error: false,          
    },
  nftTransfers: {
        data: null,
        loading: true,
        error: false,          
    },
  nfts: {
        data: null,
        loading: true,
        error: false,          
    },
  nft: {
        data: null,
        loading: true,
        error: false,          
    },
  nftTransfersByBlock: {
        data: null,
        loading: true,
        error: false,          
    },
  nftTrades: {
        data: null,
        loading: true,
        error: false,          
    },
  nftTransfersFromToBlock: {
        data: null,
        loading: true,
        error: false,          
    },
  contractNftTransfers: {
        data: null,
        loading: true,
        error: false,          
    },
  nftOwners: {
        data: null,
        loading: true,
        error: false,          
    },
  searchNfts: {
        data: null,
        loading: true,
        error: false,          
    },
  nftLowestPrice: {
        data: null,
        loading: true,
        error: false,          
    },
  nftMetadata: {
        data: null,
        loading: true,
        error: false,          
    },
};

export const saxonMoralisReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
  case
  GET_BLOCK_REQUEST:{
      return {
            ...state,
            block: {
                ...state.block,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_BLOCK_SUCCESS:{
        return {
            ...state,
          block: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_BLOCK_FAILURE:{
        return {
            ...state,
            block: {
                ...state.block,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_DATE_TO_BLOCK_REQUEST:{
      return {
            ...state,
            dateToBlock: {
                ...state.dateToBlock,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_DATE_TO_BLOCK_SUCCESS:{
        return {
            ...state,
          dateToBlock: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_DATE_TO_BLOCK_FAILURE:{
        return {
            ...state,
            dateToBlock: {
                ...state.dateToBlock,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_LOGS_BY_ADDRESS_REQUEST:{
      return {
            ...state,
            logsByAddress: {
                ...state.logsByAddress,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_LOGS_BY_ADDRESS_SUCCESS:{
        return {
            ...state,
          logsByAddress: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_LOGS_BY_ADDRESS_FAILURE:{
        return {
            ...state,
            logsByAddress: {
                ...state.logsByAddress,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_TRANSACTION_REQUEST:{
      return {
            ...state,
            transaction: {
                ...state.transaction,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_TRANSACTION_SUCCESS:{
        return {
            ...state,
          transaction: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_TRANSACTION_FAILURE:{
        return {
            ...state,
            transaction: {
                ...state.transaction,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_TRANSACTIONS_REQUEST:{
      return {
            ...state,
            transactions: {
                ...state.transactions,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_TRANSACTIONS_SUCCESS:{
        return {
            ...state,
          transactions: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_TRANSACTIONS_FAILURE:{
        return {
            ...state,
            transactions: {
                ...state.transactions,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_NATIVE_BALANCE_REQUEST:{
      return {
            ...state,
            nativeBalance: {
                ...state.nativeBalance,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_NATIVE_BALANCE_SUCCESS:{
        return {
            ...state,
          nativeBalance: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_NATIVE_BALANCE_FAILURE:{
        return {
            ...state,
            nativeBalance: {
                ...state.nativeBalance,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_TOKEN_BALANCES_REQUEST:{
      return {
            ...state,
            tokenBalances: {
                ...state.tokenBalances,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_TOKEN_BALANCES_SUCCESS:{
        return {
            ...state,
          tokenBalances: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_TOKEN_BALANCES_FAILURE:{
        return {
            ...state,
            tokenBalances: {
                ...state.tokenBalances,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_TOKEN_TRANSFERS_REQUEST:{
      return {
            ...state,
            tokenTransfers: {
                ...state.tokenTransfers,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_TOKEN_TRANSFERS_SUCCESS:{
        return {
            ...state,
          tokenTransfers: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_TOKEN_TRANSFERS_FAILURE:{
        return {
            ...state,
            tokenTransfers: {
                ...state.tokenTransfers,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_TOKEN_METADATA_REQUEST:{
      return {
            ...state,
            tokenMetadata: {
                ...state.tokenMetadata,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_TOKEN_METADATA_SUCCESS:{
        return {
            ...state,
          tokenMetadata: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_TOKEN_METADATA_FAILURE:{
        return {
            ...state,
            tokenMetadata: {
                ...state.tokenMetadata,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_TOKEN_METADATA_BY_SYMBOL_REQUEST:{
      return {
            ...state,
            tokenMetadataBySymbol: {
                ...state.tokenMetadataBySymbol,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_TOKEN_METADATA_BY_SYMBOL_SUCCESS:{
        return {
            ...state,
          tokenMetadataBySymbol: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_TOKEN_METADATA_BY_SYMBOL_FAILURE:{
        return {
            ...state,
            tokenMetadataBySymbol: {
                ...state.tokenMetadataBySymbol,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_TOKEN_PRICE_REQUEST:{
      return {
            ...state,
            tokenPrice: {
                ...state.tokenPrice,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_TOKEN_PRICE_SUCCESS:{
        return {
            ...state,
          tokenPrice: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_TOKEN_PRICE_FAILURE:{
        return {
            ...state,
            tokenPrice: {
                ...state.tokenPrice,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_TOKEN_ADDRESS_TRANSFERS_REQUEST:{
      return {
            ...state,
            tokenAddressTransfers: {
                ...state.tokenAddressTransfers,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_TOKEN_ADDRESS_TRANSFERS_SUCCESS:{
        return {
            ...state,
          tokenAddressTransfers: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_TOKEN_ADDRESS_TRANSFERS_FAILURE:{
        return {
            ...state,
            tokenAddressTransfers: {
                ...state.tokenAddressTransfers,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_TOKEN_ALLOWANCE_REQUEST:{
      return {
            ...state,
            tokenAllowance: {
                ...state.tokenAllowance,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_TOKEN_ALLOWANCE_SUCCESS:{
        return {
            ...state,
          tokenAllowance: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_TOKEN_ALLOWANCE_FAILURE:{
        return {
            ...state,
            tokenAllowance: {
                ...state.tokenAllowance,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_ALL_TOKEN_IDS_REQUEST:{
      return {
            ...state,
            allTokenIds: {
                ...state.allTokenIds,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_ALL_TOKEN_IDS_SUCCESS:{
        return {
            ...state,
          allTokenIds: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_ALL_TOKEN_IDS_FAILURE:{
        return {
            ...state,
            allTokenIds: {
                ...state.allTokenIds,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_TOKEN_ID_METADATA_REQUEST:{
      return {
            ...state,
            tokenIdMetadata: {
                ...state.tokenIdMetadata,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_TOKEN_ID_METADATA_SUCCESS:{
        return {
            ...state,
          tokenIdMetadata: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_TOKEN_ID_METADATA_FAILURE:{
        return {
            ...state,
            tokenIdMetadata: {
                ...state.tokenIdMetadata,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_TOKEN_ID_OWNERS_REQUEST:{
      return {
            ...state,
            tokenIdOwners: {
                ...state.tokenIdOwners,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_TOKEN_ID_OWNERS_SUCCESS:{
        return {
            ...state,
          tokenIdOwners: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_TOKEN_ID_OWNERS_FAILURE:{
        return {
            ...state,
            tokenIdOwners: {
                ...state.tokenIdOwners,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_WALLET_TOKEN_ID_TRANSFERS_REQUEST:{
      return {
            ...state,
            walletTokenIdTransfers: {
                ...state.walletTokenIdTransfers,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_WALLET_TOKEN_ID_TRANSFERS_SUCCESS:{
        return {
            ...state,
          walletTokenIdTransfers: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_WALLET_TOKEN_ID_TRANSFERS_FAILURE:{
        return {
            ...state,
            walletTokenIdTransfers: {
                ...state.walletTokenIdTransfers,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  RESOLVE_DOMAIN_REQUEST:{
      return {
            ...state,
            resolveDomain: {
                ...state.resolveDomain,
                loading: true,
                error: false,          
            },            
      };
    }
    case RESOLVE_DOMAIN_SUCCESS:{
        return {
            ...state,
          resolveDomain: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case RESOLVE_DOMAIN_FAILURE:{
        return {
            ...state,
            resolveDomain: {
                ...state.resolveDomain,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  RESOLVE_ADDRESS_REQUEST:{
      return {
            ...state,
            resolveAddress: {
                ...state.resolveAddress,
                loading: true,
                error: false,          
            },            
      };
    }
    case RESOLVE_ADDRESS_SUCCESS:{
        return {
            ...state,
          resolveAddress: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case RESOLVE_ADDRESS_FAILURE:{
        return {
            ...state,
            resolveAddress: {
                ...state.resolveAddress,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_PAIR_RESERVES_REQUEST:{
      return {
            ...state,
            pairReserves: {
                ...state.pairReserves,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_PAIR_RESERVES_SUCCESS:{
        return {
            ...state,
          pairReserves: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_PAIR_RESERVES_FAILURE:{
        return {
            ...state,
            pairReserves: {
                ...state.pairReserves,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_PAIR_ADDRESS_REQUEST:{
      return {
            ...state,
            pairAddress: {
                ...state.pairAddress,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_PAIR_ADDRESS_SUCCESS:{
        return {
            ...state,
          pairAddress: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_PAIR_ADDRESS_FAILURE:{
        return {
            ...state,
            pairAddress: {
                ...state.pairAddress,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_NFT_TRANSFERS_REQUEST:{
      return {
            ...state,
            nftTransfers: {
                ...state.nftTransfers,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_NFT_TRANSFERS_SUCCESS:{
        return {
            ...state,
          nftTransfers: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_NFT_TRANSFERS_FAILURE:{
        return {
            ...state,
            nftTransfers: {
                ...state.nftTransfers,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_NFTS_REQUEST:{
      return {
            ...state,
            nfts: {
                ...state.nfts,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_NFTS_SUCCESS:{
        return {
            ...state,
          nfts: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_NFTS_FAILURE:{
        return {
            ...state,
            nfts: {
                ...state.nfts,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_NFT_REQUEST:{
      return {
            ...state,
            nft: {
                ...state.nft,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_NFT_SUCCESS:{
        return {
            ...state,
          nft: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_NFT_FAILURE:{
        return {
            ...state,
            nft: {
                ...state.nft,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_NFT_TRANSFERS_BY_BLOCK_REQUEST:{
      return {
            ...state,
            nftTransfersByBlock: {
                ...state.nftTransfersByBlock,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_NFT_TRANSFERS_BY_BLOCK_SUCCESS:{
        return {
            ...state,
          nftTransfersByBlock: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_NFT_TRANSFERS_BY_BLOCK_FAILURE:{
        return {
            ...state,
            nftTransfersByBlock: {
                ...state.nftTransfersByBlock,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_NFT_TRADES_REQUEST:{
      return {
            ...state,
            nftTrades: {
                ...state.nftTrades,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_NFT_TRADES_SUCCESS:{
        return {
            ...state,
          nftTrades: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_NFT_TRADES_FAILURE:{
        return {
            ...state,
            nftTrades: {
                ...state.nftTrades,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_NFT_TRANSFERS_FROM_TO_BLOCK_REQUEST:{
      return {
            ...state,
            nftTransfersFromToBlock: {
                ...state.nftTransfersFromToBlock,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_NFT_TRANSFERS_FROM_TO_BLOCK_SUCCESS:{
        return {
            ...state,
          nftTransfersFromToBlock: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_NFT_TRANSFERS_FROM_TO_BLOCK_FAILURE:{
        return {
            ...state,
            nftTransfersFromToBlock: {
                ...state.nftTransfersFromToBlock,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_CONTRACT_NFT_TRANSFERS_REQUEST:{
      return {
            ...state,
            contractNftTransfers: {
                ...state.contractNftTransfers,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_CONTRACT_NFT_TRANSFERS_SUCCESS:{
        return {
            ...state,
          contractNftTransfers: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_CONTRACT_NFT_TRANSFERS_FAILURE:{
        return {
            ...state,
            contractNftTransfers: {
                ...state.contractNftTransfers,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_NFT_OWNERS_REQUEST:{
      return {
            ...state,
            nftOwners: {
                ...state.nftOwners,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_NFT_OWNERS_SUCCESS:{
        return {
            ...state,
          nftOwners: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_NFT_OWNERS_FAILURE:{
        return {
            ...state,
            nftOwners: {
                ...state.nftOwners,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  SEARCH_NFTS_REQUEST:{
      return {
            ...state,
            searchNfts: {
                ...state.searchNfts,
                loading: true,
                error: false,          
            },            
      };
    }
    case SEARCH_NFTS_SUCCESS:{
        return {
            ...state,
          searchNfts: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case SEARCH_NFTS_FAILURE:{
        return {
            ...state,
            searchNfts: {
                ...state.searchNfts,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_NFT_LOWEST_PRICE_REQUEST:{
      return {
            ...state,
            nftLowestPrice: {
                ...state.nftLowestPrice,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_NFT_LOWEST_PRICE_SUCCESS:{
        return {
            ...state,
          nftLowestPrice: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_NFT_LOWEST_PRICE_FAILURE:{
        return {
            ...state,
            nftLowestPrice: {
                ...state.nftLowestPrice,
                loading: false,
                error: payload,                          
            },
        };
        }
  case
  GET_NFT_METADATA_REQUEST:{
      return {
            ...state,
            nftMetadata: {
                ...state.nftMetadata,
                loading: true,
                error: false,          
            },            
      };
    }
    case GET_NFT_METADATA_SUCCESS:{
        return {
            ...state,
          nftMetadata: {                
                loading: false,
                error: false,          
                ...payload
            },
        };
    }
    case GET_NFT_METADATA_FAILURE:{
        return {
            ...state,
            nftMetadata: {
                ...state.nftMetadata,
                loading: false,
                error: payload,                          
            },
        };
        }
      default:
      return state;
  }
};
// DASHBOARDS
export const CREATE_DASHBOARD_FAVOURITE_FAILURE = "CREATE_DASHBOARD_FAVOURITE_FAILURE";
export const CREATE_DASHBOARD_FAVOURITE_SUCCESS = "CREATE_DASHBOARD_FAVOURITE_SUCCESS";
export const CREATE_DASHBOARD_FAVOURITE_REQUEST = "CREATE_DASHBOARD_FAVOURITE_REQUEST";
export const DELETE_DASHBOARD_FAVOURITE_FAILURE = "DELETE_DASHBOARD_FAVOURITE_FAILURE";
export const DELETE_DASHBOARD_FAVOURITE_REQUEST = "DELETE_DASHBOARD_FAVOURITE_REQUEST";
export const DELETE_DASHBOARD_FAVOURITE_SUCCESS = "DELETE_DASHBOARD_FAVOURITE_SUCCESS";
export const GET_DASHBOARD_FAVOURITES_FAILURE = "GET_DASHBOARD_FAVOURITES_FAILURE";
export const GET_DASHBOARD_FAVOURITES_REQUEST = "GET_DASHBOARD_FAVOURITES_REQUEST";
export const GET_DASHBOARD_FAVOURITES_SUCCESS = "GET_DASHBOARD_FAVOURITES_SUCCESS";

//VERIFICATION
export const INIT_VERIFICATION_FAILURE = "INIT_VERIFICATION_FAILURE";
export const INIT_VERIFICATION_SUCCESS = "INIT_VERIFICATION_SUCCESS";
export const INIT_VERIFICATION_REQUEST = "INIT_VERIFICATION_REQUEST";
export const GET_VERIFICATION_STATUS_REQUEST = "GET_VERIFICATION_STATUS_REQUEST";
export const GET_VERIFICATION_STATUS_SUCCESS = "GET_VERIFICATION_STATUS_SUCCESS";
export const GET_VERIFICATION_STATUS_FAILURE = "GET_VERIFICATION_STATUS_FAILURE";
export const SUBMIT_VERIFICATION_SUCCESS = "SUBMIT_VERIFICATION_SUCCESS";
export const SUBMIT_VERIFICATION_FAILURE = "SUBMIT_VERIFICATION_FAILURE";
export const SUBMIT_VERIFICATION_REQUEST = "SUBMIT_VERIFICATION_REQUEST";

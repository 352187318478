import { GET_MARKETS_REQUEST, GET_MARKETS_SUCCESS, GET_MARKETS_FAILURE } from "../actions/types/types";

const initialState = {
	loading: true,
	error: false,
	markets: window.SaxonData?.markets ? window.SaxonData?.markets : [],
};

export const marketsReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_MARKETS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_MARKETS_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case GET_MARKETS_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
};

import { GET_POSTS, CREATE_POST, POSTS_ERROR, CREATE_NOTIFICATION, NOTIFICATION_ERROR, GET_CHANNELS, GET_CHANNEL, CHANNELS_ERROR, CHANNEL_ERROR } from "../actions/types/types";

const SaxonData = window.SaxonData;
const channels = SaxonData?.channels;
const user = SaxonData?.user;
const userid = SaxonData?.userid;
const channel = SaxonData?.channel;

const initialState = {
	posts: [],
	channel: channel || {},
	channels: channels || [],
	user: user,
	currentUserId: userid,
	notification: {},
	loading: true,
	error: {},
};

export const coffeehouseReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_POSTS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case GET_CHANNELS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case GET_CHANNEL:
			return {
				...state,
				loading: false,
				...payload,
			};
		case CREATE_POST:
			return {
				...state,
				loading: false,
				...payload,
			};
		case CREATE_NOTIFICATION:
			return {
				...state,
				loading: false,
				...payload,
			};
		case POSTS_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case CHANNELS_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case CHANNEL_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case NOTIFICATION_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
};

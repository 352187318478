export const GET_ADMIN_DEAL_REQUEST = "GET_ADMIN_DEAL_REQUEST";
export const GET_ADMIN_DEAL_SUCCESS = "GET_ADMIN_DEAL_SUCCESS";
export const GET_ADMIN_DEAL_FAILURE = "GET_ADMIN_DEAL_FAILURE";

export const GET_ADMIN_DEALS_REQUEST = "GET_ADMIN_DEALS_REQUEST";
export const GET_ADMIN_DEALS_SUCCESS = "GET_ADMIN_DEALS_SUCCESS";
export const GET_ADMIN_DEALS_FAILURE = "GET_ADMIN_DEALS_FAILURE";

export const CREATE_ADMIN_DEAL_REQUEST = "CREATE_ADMIN_DEAL_REQUEST";
export const CREATE_ADMIN_DEAL_SUCCESS = "CREATE_ADMIN_DEAL_SUCCESS";
export const CREATE_ADMIN_DEAL_FAILURE = "CREATE_ADMIN_DEAL_FAILURE";

export const UPDATE_ADMIN_DEAL_REQUEST = "UPDATE_ADMIN_DEAL_REQUEST";
export const UPDATE_ADMIN_DEAL_SUCCESS = "UPDATE_ADMIN_DEAL_SUCCESS";
export const UPDATE_ADMIN_DEAL_FAILURE = "UPDATE_ADMIN_DEAL_FAILURE";

export const DELETE_ADMIN_DEAL_REQUEST = "DELETE_ADMIN_DEAL_REQUEST";
export const DELETE_ADMIN_DEAL_SUCCESS = "DELETE_ADMIN_DEAL_SUCCESS";
export const DELETE_ADMIN_DEAL_FAILURE = "DELETE_ADMIN_DEAL_FAILURE";

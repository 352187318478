import type { Reducer } from "@reduxjs/toolkit";
import {
	GET_USER_REQUEST,
	GET_USER_SUCCESS,
	GET_USER_FAILURE,
	GET_CONNECTED_USERS_REQUEST,
	GET_CONNECTED_USERS_SUCCESS,
	GET_CONNECTED_USERS_FAILURE,
	UPDATE_USER_REQUEST,
	UPDATE_USER_SUCCESS,
	DELETE_USER_REQUEST,
	UPDATE_USER_FAILURE,
	DELETE_USER_FAILURE,
	DELETE_USER_SUCCESS,
} from "../actions/types/types";
import { User } from "App/src/types/api/User";

type IState = {
	loading: boolean;
	user: User | null;
	error: Error | null;
	connectedUsers: User["id"][] | null;
};

const initialState: IState = {
	user: window?.user ? window?.user : null,
	loading: true,
	error: null,
	connectedUsers: window?.connectedUsers ? window?.connectedUsers : null,
};

export const userReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_USER_REQUEST:
			return {
				...state,
				loading: true,
			};

		case GET_USER_SUCCESS:
			return {
				...state,
				...payload,
			};

		case GET_USER_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case UPDATE_USER_REQUEST:
			return {
				...state,
				loading: true,
			};

		case UPDATE_USER_SUCCESS:
			return {
				...state,
				...payload,
			};

		case UPDATE_USER_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case DELETE_USER_REQUEST:
			return {
				...state,
				loading: true,
			};
		case DELETE_USER_SUCCESS:
			return {
				...state,
				...payload,
			};
		case DELETE_USER_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case GET_CONNECTED_USERS_REQUEST:
			return {
				...state,
				loading: true,
			};

		case GET_CONNECTED_USERS_SUCCESS:
			return {
				...state,
				...payload,
			};

		case GET_CONNECTED_USERS_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
};

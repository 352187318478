//POSTS
export const GET_POSTS = "GET_POSTS";
export const CREATE_POST = "CREATE_POST";
export const POSTS_ERROR = "POSTS_ERROR";

//COFEEHOUSE
export const GET_CHANNELS = "GET_CHANNELS";
export const CHANNELS_ERROR = "CHANNELS_ERROR";
export const GET_CHANNEL = "GET_CHANNEL";
export const CHANNEL_ERROR = "CHANNEL_ERROR";

import { SHOW_CHART_LOADER, GET_CHART_DATA, CHART_ERROR } from "../actions/types/types";

const SaxonData = window.SaxonData?.Swaps;

const initialState = {
	chartData: SaxonData?.AssetChartData ? SaxonData?.AssetChartData : [],
	loading: true,
	error: false,
};

export const chartsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SHOW_CHART_LOADER:
			return { ...state, loading: true };
		case GET_CHART_DATA:
			return {
				...state,
				loading: false,
				...payload,
			};
		case CHART_ERROR:
			return { ...state, loading: false, error: payload };
		default:
			return state;
	}
};

import type { Reducer } from "@reduxjs/toolkit";
import { TokenList } from "../../types/api/TokenList";
import { TransactionReceipt } from "@ethersproject/providers";

import { GET_WALLET_SUCCESS, GET_WALLET_REQUEST, GET_WALLET_ERROR, SWAP_RESET_ERROR, SWAP_RESET_REQUEST, SWAP_RESET_SUCCESS } from "../actions/types/types";

const SaxonData = window.SaxonData?.Swaps;
interface IState {
	tokenList: TokenList | null;
	currentUser: any;
	approvedTokens: any;
	sellAmount: string;
	buyAmount: string;
	loading: boolean;
	provider: any;
	swapTx: {
		isDone: boolean | null;
		isPending: boolean | null;
		hash: string | null;
		receipt: TransactionReceipt | null;
		reset?: () => void;
	};
	Ox_error: boolean;
	Ox_loading: boolean;
	quoteData: any;
	buyChartData: any;
	sellChartData: any;
	wallet: any;
	error: any;
	tooltipError?: string;
}

const initialState: IState = {
	tokenList: SaxonData?.tokenList || null,
	currentUser: SaxonData?.user,
	wallet: {},
	approvedTokens: {},
	sellAmount: "",
	buyAmount: "",
	loading: true,
	provider: null,
	swapTx: {
		isDone: null,
		isPending: null,
		hash: null,
		receipt: null,
	},
	error: null,
	Ox_error: false,
	Ox_loading: false,
	quoteData: null,
	buyChartData: null,
	sellChartData: null,
};

export const swapsReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_WALLET_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_WALLET_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_WALLET_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case SWAP_RESET_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case SWAP_RESET_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case SWAP_RESET_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};

		default:
			return state;
	}
};

import {
	GET_ORGANISATION_REQUEST,
	GET_ORGANISATION_SUCCESS,
	GET_ORGANISATION_FAILURE,
	CREATE_ORGANISATION_REQUEST,
	CREATE_ORGANISATION_SUCCESS,
	CREATE_ORGANISATION_FAILURE,
	UPDATE_ORGANISATION_REQUEST,
	UPDATE_ORGANISATION_SUCCESS,
	UPDATE_ORGANISATION_FAILURE,
	UPDATE_MEMBER_ROLE_REQUEST,
	UPDATE_MEMBER_ROLE_SUCCESS,
	UPDATE_MEMBER_ROLE_FAILURE,
	UPLOAD_ORGANISATION_IMAGE_REQUEST,
	UPLOAD_ORGANISATION_IMAGE_SUCCESS,
	UPLOAD_ORGANISATION_IMAGE_FAILURE,
	GET_ORGANISATION_ACCESS_TOKEN_REQUEST,
	GET_ORGANISATION_ACCESS_TOKEN_SUCCESS,
	GET_ORGANISATION_ACCESS_TOKEN_FAILURE,
	SWAP_USER_ORGANISATION_FAILURE,
	SWAP_USER_ORGANISATION_REQUEST,
	SWAP_USER_ORGANISATION_SUCCESS,
} from "../actions/types/types";

let organisation = window.SaxonData?.organisation ? window.SaxonData.organisation : "";
let accessToken = window.SaxonData?.access_token ? window.SaxonData.access_token : "";
const initialState = {
	loading: false,
	error: false,
	...organisation,
	access_token: accessToken,
	loading_role_status: false,
};

export const organisationReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_ORGANISATION_REQUEST:
			return {
				...state,
				loading: true,
			};

		case GET_ORGANISATION_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};

		case GET_ORGANISATION_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case CREATE_ORGANISATION_REQUEST:
			return {
				...state,
				loading: true,
			};

		case CREATE_ORGANISATION_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};

		case CREATE_ORGANISATION_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case UPDATE_ORGANISATION_REQUEST:
			return {
				...state,
				loading: true,
			};

		case UPDATE_ORGANISATION_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};

		case UPDATE_ORGANISATION_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case UPDATE_MEMBER_ROLE_REQUEST:
			return {
				...state,
				loading_role_status: true,
			};

		case UPDATE_MEMBER_ROLE_SUCCESS:
			return {
				...state,
				loading_role_status: false,
				...payload,
			};

		case UPDATE_MEMBER_ROLE_FAILURE:
			return {
				...state,
				loading_role_status: false,
				error: payload,
			};
		case UPLOAD_ORGANISATION_IMAGE_REQUEST:
			return {
				...state,
				loading: true,
			};

		case UPLOAD_ORGANISATION_IMAGE_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};

		case UPLOAD_ORGANISATION_IMAGE_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case GET_ORGANISATION_ACCESS_TOKEN_REQUEST:
			return {
				...state,
				loading: true,
			};

		case GET_ORGANISATION_ACCESS_TOKEN_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};

		case GET_ORGANISATION_ACCESS_TOKEN_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case SWAP_USER_ORGANISATION_REQUEST:
			return {
				...state,
				loading: true,
			};
		case SWAP_USER_ORGANISATION_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case SWAP_USER_ORGANISATION_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		default:
			return state;
	}
};

export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAILURE = "GET_COMPANY_FAILURE";

export const HAS_PIPEDRIVE_DEALS_REQUEST = "HAS_PIPEDRIVE_DEALS_REQUEST";
export const HAS_PIPEDRIVE_DEALS_SUCCESS = "HAS_PIPEDRIVE_DEALS_SUCCESS";
export const HAS_PIPEDRIVE_DEALS_FAILURE = "HAS_PIPEDRIVE_DEALS_FAILURE";

export const GET_COMPANIES_REQUEST = "GET_COMPANIES_REQUEST";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILURE = "GET_COMPANIES_FAILURE";

export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILURE = "CREATE_COMPANY_FAILURE";

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

//PP
export const GET_PRIVATE_PLACEMENTS_TERMS = "GET_PRIVATE_PLACEMENTS_TERMS";
export const GET_PRIVATE_PLACEMENTS_TERM = "GET_PRIVATE_PLACEMENTS_TERM";
export const CREATE_PRIVATE_PLACEMENTS_TERM = "CREATE_PRIVATE_PLACEMENTS_TERM";
export const UPDATE_PRIVATE_PLACEMENTS_TERM = "UPDATE_PRIVATE_PLACEMENTS_TERM";
export const DELETE_PRIVATE_PLACEMENTS_TERM = "DELETE_PRIVATE_PLACEMENTS_TERM";
export const GET_PRIVATE_PLACEMENTS_TERM_TRANCHES = "GET_PRIVATE_PLACEMENTS_TERM_TRANCHES";
export const GET_PRIVATE_PLACEMENTS_TERM_TRANCHE = "GET_PRIVATE_PLACEMENTS_TERM_TRANCHE";
export const CREATE_PRIVATE_PLACEMENTS_TERM_TRANCHE = "CREATE_PRIVATE_PLACEMENTS_TERM_TRANCHE";
export const UPDATE_PRIVATE_PLACEMENTS_TERM_TRANCHE = "UPDATE_PRIVATE_PLACEMENTS_TERM_TRANCHE";
export const DELETE_PRIVATE_PLACEMENTS_TERM_TRANCHE = "DELETE_PRIVATE_PLACEMENTS_TERM_TRANCHE";
export const DELETE_PRIVATE_PLACEMENTS_TERM_TRANCHES = "DELETE_PRIVATE_PLACEMENTS_TERM_TRANCHES";
export const PRIVATE_PLACEMENTS_ERROR = "PRIVATE_PLACEMENTS_ERROR";
export const PRIVATE_PLACEMENTS_ADMIN_ERROR = "PRIVATE_PLACEMENTS_ADMIN_ERROR";
export const PRIVATE_PLACEMENTS_SHOW_SIDEBAR_LOADER = "PRIVATE_PLACEMENTS_SHOW_SIDEBAR_LOADER";
export const PRIVATE_PLACEMENTS_SHOW_WIDGET_LOADER = "PRIVATE_PLACEMENTS_SHOW_WIDGET_LOADER";
export const PRIVATE_PLACEMENTS_SHOW_LOADER = "PRIVATE_PLACEMENTS_SHOW_LOADER";

import type { Reducer } from "@reduxjs/toolkit";
import { config } from "Config/config";
import type { OtcTerm, Tranche } from "Types/api/OTC";
import type { User } from "App/src/types/api/User";
import {
	GET_OTC_TERMS,
	GET_OTC_TERM,
	CREATE_OTC_TERM,
	UPDATE_OTC_TERM,
	DELETE_OTC_TERM,
	GET_OTC_TERM_TRANCHES,
	GET_OTC_TERM_TRANCHE,
	CREATE_OTC_TERM_TRANCHE,
	UPDATE_OTC_TERM_TRANCHE,
	DELETE_OTC_TERM_TRANCHE,
	DELETE_OTC_TERM_TRANCHES,
	SHOW_OTC_LOADER,
	SHOW_WIDGET_LOADER,
	SHOW_SIDEBAR_LOADER,
	OTC_ERROR,
	ADMIN_ERROR,
	GET_OTC_CONTRACT_BALANCES_REQUEST,
	GET_OTC_CONTRACT_BALANCES_SUCCESS,
	GET_OTC_CONTRACT_BALANCES_FAILURE,
} from "../actions/types/types";

const SaxonData = window.SaxonData?.Admin;

interface IState {
	currentUser: User;
	companyid: string;
	dealid: string;
	userid: string;
	allTerms: OtcTerm[] | [];
	terms: OtcTerm;
	tranches?: Tranche[];
	tranche?: Tranche;
	loading: boolean;
	sidebarLoading: boolean;
	widgetLoading: boolean;
	error: string;
	defaultPaymentTokens: typeof config.OTC_DEFAULT_PAYMENT_TOKENS;
	balances: any[];
}
const initialState: IState = {
	currentUser: SaxonData?.user,
	companyid: SaxonData?.companyid,
	dealid: SaxonData?.dealid,
	userid: SaxonData?.userid,
	loading: true,
	sidebarLoading: true,
	widgetLoading: true,
	defaultPaymentTokens: config.OTC_DEFAULT_PAYMENT_TOKENS,
	balances: [],
	allTerms: null,
	terms: null,
	tranches: null,
	tranche: null,
	error: null,
};

export const otcReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case SHOW_OTC_LOADER:
			return {
				...state,
				loading: true,
			};
		case SHOW_SIDEBAR_LOADER:
			return {
				...state,
				sidebarLoading: true,
			};
		case SHOW_WIDGET_LOADER:
			return {
				...state,
				widgetLoading: true,
			};
		case GET_OTC_TERMS:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case GET_OTC_TERM:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case CREATE_OTC_TERM:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case UPDATE_OTC_TERM:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case DELETE_OTC_TERM:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case GET_OTC_TERM_TRANCHES:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case GET_OTC_TERM_TRANCHE:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case CREATE_OTC_TERM_TRANCHE:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				...payload,
			};
		case UPDATE_OTC_TERM_TRANCHE:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case DELETE_OTC_TERM_TRANCHE:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case DELETE_OTC_TERM_TRANCHES:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case ADMIN_ERROR:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				error: payload,
			};
		case OTC_ERROR:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				error: payload,
			};
		case GET_OTC_CONTRACT_BALANCES_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_OTC_CONTRACT_BALANCES_SUCCESS:
			return {
				...state,
				loading: false,
				...payload,
			};
		case GET_OTC_CONTRACT_BALANCES_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
};

// rewrite all of the above to use redux toolkit

// import { createSlice } from "@reduxjs/toolkit";

// const SaxonData = window.SaxonData?.Admin;

// const initialState = {
// 	currentUser: SaxonData?.user,
// 	companyid: SaxonData?.companyid,
// 	dealid: SaxonData?.dealid,
// 	userid: SaxonData?.userid,
// 	allTerms: [],
// 	terms: [],
// 	tranches: [],
// 	loading: true,
// 	sidebarLoading: true,
// 	widgetLoading: true,
// 	error: null,
// 	defaultPaymentTokens: config.OTC_DEFAULT_PAYMENT_TOKENS,
// 	balances: [],
// };

// export const otcSlice = createSlice({
// 	name: "OTC",
// 	initialState,
// 	reducers: {
// 		showOTCLoader: (state) => {
// 			state.loading = true;
// 		},
// 		showSidebarLoader: (state) => {
// 			state.sidebarLoading = true;
// 		},
// 		showWidgetLoader: (state) => {
// 			state.widgetLoading = true;
// 		},
// 		getOTCTerms: (state, action) => {
// 			state.loading = false;
// 			state.sidebarLoading = false;
// 			state.widgetLoading = false;
// 			state.allTerms = action.payload.allTerms;
// 			state.terms = action.payload.terms;
// 		},
// 		getOTCTerm: (state, action) => {
// 			state.loading = false;
// 			state.sidebarLoading = false;
// 			state.widgetLoading = false;
// 			state.allTerms = action.payload.allTerms;
// 			state.terms = action.payload.terms;
// 		},
// 		createOTCTerm: (state, action) => {
// 			state.loading = false;
// 			state.sidebarLoading = false;
// 			state.widgetLoading = false;
// 			state.allTerms = action.payload.allTerms;
// 			state.terms = action.payload.terms;
// 		},
// 		updateOTCTerm: (state, action) => {
// 			state.loading = false;
// 			state.sidebarLoading = false;
// 			state.widgetLoading = false;
// 			state.allTerms = action.payload.allTerms;
// 			state.terms = action.payload.terms;
// 		},
// 		deleteOTCTerm: (state, action) => {
// 			state.loading = false;
// 			state.sidebarLoading = false;
// 			state.widgetLoading = false;
// 			state.allTerms = action.payload.allTerms;
// 			state.terms = action.payload.terms;
// 		},
// 		getOTCTermTranches: (state, action) => {
// 			state.loading = false;
// 			state.sidebarLoading = false;
// 			state.widgetLoading = false;
// 			state.allTerms = action.payload.allTerms;
// 			state.terms = action.payload.terms;
// 			state.tranches = action.payload.tranches;
// 		},
// 		getOTCTermTranche: (state, action) => {
// 			state.loading = false;
// 			state.sidebarLoading = false;
// 			state.widgetLoading = false;
// 			state.allTerms = action.payload.allTerms;
// 			state.terms = action.payload.terms;
// 			state.tranches = action.payload.tranches;
// 		},
// 		createOTCTermTranche: (state, action) => {
// 			state.loading = false;
// 			state.sidebarLoading = false;
// 			state.widgetLoading = false;
// 			state.allTerms = action.payload.allTerms;
// 			state.terms = action.payload.terms;
// 			state.tranches = action.payload.tranches;
// 		},
// 		updateOTCTermTranche: (state, action) => {
// 			state.loading = false;
// 			state.sidebarLoading = false;
// 			state.widgetLoading = false;
// 			state.allTerms = action.payload.allTerms;
// 			state.terms = action.payload.terms;
// 			state.tranches = action.payload.tranches;
// 		},
// 		deleteOTCTermTranche: (state, action) => {
// 			state.loading = false;
// 			state.sidebarLoading = false;
// 			state.widgetLoading = false;
// 			state.allTerms = action.payload.allTerms;
// 			state.terms = action.payload.terms;
// 			state.tranches = action.payload.tranches;
// 		},
// 		deleteOTCTermTranches: (state, action) => {
// 			state.loading = false;
// 			state.sidebarLoading = false;
// 			state.widgetLoading = false;
// 			state.allTerms = action.payload.allTerms;
// 			state.terms = action.payload.terms;
// 			state.tranches = action.payload.tranches;
// 		},
// 		adminError: (state, action) => {
// 			state.loading = false;
// 			state.sidebarLoading = false;
// 			state.widgetLoading = false;
// 			state.error = action.payload;
// 		},
// 		otcError: (state, action) => {
// 			state.loading = false;
// 			state.sidebarLoading = false;
// 			state.widgetLoading = false;
// 			state.error = action.payload;
// 		},
// 		getOTCContractBalancesRequest: (state) => {
// 			state.loading = true;
// 		},
// 		getOTCContractBalancesSuccess: (state, action) => {
// 			state.loading = false;
// 			state.balances = action.payload;
// 		},
// 		getOTCContractBalancesFailure: (state, action) => {
// 			state.loading = false;
// 			state.error = action.payload;
// 		},
// 	},
// });

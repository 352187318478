import {
	GET_VERIFICATION_STATUS_REQUEST,
	GET_VERIFICATION_STATUS_SUCCESS,
	GET_VERIFICATION_STATUS_FAILURE,
	SUBMIT_VERIFICATION_REQUEST,
	SUBMIT_VERIFICATION_SUCCESS,
	SUBMIT_VERIFICATION_FAILURE,
} from "../actions/types/types";

const initialState = {
	verified: false,
	status: "Incomplete",
	loading: true,
	error: {},
};

export const verificationReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_VERIFICATION_STATUS_REQUEST:
			return {
				...state,
				loading: true,
			};

		case GET_VERIFICATION_STATUS_SUCCESS:
			return {
				...state,
				...payload,
			};

		case GET_VERIFICATION_STATUS_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case SUBMIT_VERIFICATION_REQUEST:
			return {
				...state,
				loading: true,
			};

		case SUBMIT_VERIFICATION_SUCCESS:
			return {
				...state,
				...payload,
			};

		case SUBMIT_VERIFICATION_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		default:
			return state;
	}
};


// SAXON_MORALIS STARTS


export const GET_BLOCK_REQUEST = "GET_BLOCK_REQUEST";
export const GET_BLOCK_SUCCESS = "GET_BLOCK_SUCCESS";
export const GET_BLOCK_FAILURE = "GET_BLOCK_FAILURE";  


export const GET_DATE_TO_BLOCK_REQUEST = "GET_DATE_TO_BLOCK_REQUEST";
export const GET_DATE_TO_BLOCK_SUCCESS = "GET_DATE_TO_BLOCK_SUCCESS";
export const GET_DATE_TO_BLOCK_FAILURE = "GET_DATE_TO_BLOCK_FAILURE";  


export const GET_LOGS_BY_ADDRESS_REQUEST = "GET_LOGS_BY_ADDRESS_REQUEST";
export const GET_LOGS_BY_ADDRESS_SUCCESS = "GET_LOGS_BY_ADDRESS_SUCCESS";
export const GET_LOGS_BY_ADDRESS_FAILURE = "GET_LOGS_BY_ADDRESS_FAILURE";  


export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAILURE = "GET_TRANSACTION_FAILURE";  


export const GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILURE = "GET_TRANSACTIONS_FAILURE";  


export const GET_NATIVE_BALANCE_REQUEST = "GET_NATIVE_BALANCE_REQUEST";
export const GET_NATIVE_BALANCE_SUCCESS = "GET_NATIVE_BALANCE_SUCCESS";
export const GET_NATIVE_BALANCE_FAILURE = "GET_NATIVE_BALANCE_FAILURE";  


export const GET_TOKEN_BALANCES_REQUEST = "GET_TOKEN_BALANCES_REQUEST";
export const GET_TOKEN_BALANCES_SUCCESS = "GET_TOKEN_BALANCES_SUCCESS";
export const GET_TOKEN_BALANCES_FAILURE = "GET_TOKEN_BALANCES_FAILURE";  


export const GET_TOKEN_TRANSFERS_REQUEST = "GET_TOKEN_TRANSFERS_REQUEST";
export const GET_TOKEN_TRANSFERS_SUCCESS = "GET_TOKEN_TRANSFERS_SUCCESS";
export const GET_TOKEN_TRANSFERS_FAILURE = "GET_TOKEN_TRANSFERS_FAILURE";  


export const GET_TOKEN_METADATA_REQUEST = "GET_TOKEN_METADATA_REQUEST";
export const GET_TOKEN_METADATA_SUCCESS = "GET_TOKEN_METADATA_SUCCESS";
export const GET_TOKEN_METADATA_FAILURE = "GET_TOKEN_METADATA_FAILURE";  


export const GET_TOKEN_METADATA_BY_SYMBOL_REQUEST = "GET_TOKEN_METADATA_BY_SYMBOL_REQUEST";
export const GET_TOKEN_METADATA_BY_SYMBOL_SUCCESS = "GET_TOKEN_METADATA_BY_SYMBOL_SUCCESS";
export const GET_TOKEN_METADATA_BY_SYMBOL_FAILURE = "GET_TOKEN_METADATA_BY_SYMBOL_FAILURE";  


export const GET_TOKEN_PRICE_REQUEST = "GET_TOKEN_PRICE_REQUEST";
export const GET_TOKEN_PRICE_SUCCESS = "GET_TOKEN_PRICE_SUCCESS";
export const GET_TOKEN_PRICE_FAILURE = "GET_TOKEN_PRICE_FAILURE";  


export const GET_TOKEN_ADDRESS_TRANSFERS_REQUEST = "GET_TOKEN_ADDRESS_TRANSFERS_REQUEST";
export const GET_TOKEN_ADDRESS_TRANSFERS_SUCCESS = "GET_TOKEN_ADDRESS_TRANSFERS_SUCCESS";
export const GET_TOKEN_ADDRESS_TRANSFERS_FAILURE = "GET_TOKEN_ADDRESS_TRANSFERS_FAILURE";  


export const GET_TOKEN_ALLOWANCE_REQUEST = "GET_TOKEN_ALLOWANCE_REQUEST";
export const GET_TOKEN_ALLOWANCE_SUCCESS = "GET_TOKEN_ALLOWANCE_SUCCESS";
export const GET_TOKEN_ALLOWANCE_FAILURE = "GET_TOKEN_ALLOWANCE_FAILURE";  


export const GET_ALL_TOKEN_IDS_REQUEST = "GET_ALL_TOKEN_IDS_REQUEST";
export const GET_ALL_TOKEN_IDS_SUCCESS = "GET_ALL_TOKEN_IDS_SUCCESS";
export const GET_ALL_TOKEN_IDS_FAILURE = "GET_ALL_TOKEN_IDS_FAILURE";  


export const GET_TOKEN_ID_METADATA_REQUEST = "GET_TOKEN_ID_METADATA_REQUEST";
export const GET_TOKEN_ID_METADATA_SUCCESS = "GET_TOKEN_ID_METADATA_SUCCESS";
export const GET_TOKEN_ID_METADATA_FAILURE = "GET_TOKEN_ID_METADATA_FAILURE";  


export const GET_TOKEN_ID_OWNERS_REQUEST = "GET_TOKEN_ID_OWNERS_REQUEST";
export const GET_TOKEN_ID_OWNERS_SUCCESS = "GET_TOKEN_ID_OWNERS_SUCCESS";
export const GET_TOKEN_ID_OWNERS_FAILURE = "GET_TOKEN_ID_OWNERS_FAILURE";  


export const GET_WALLET_TOKEN_ID_TRANSFERS_REQUEST = "GET_WALLET_TOKEN_ID_TRANSFERS_REQUEST";
export const GET_WALLET_TOKEN_ID_TRANSFERS_SUCCESS = "GET_WALLET_TOKEN_ID_TRANSFERS_SUCCESS";
export const GET_WALLET_TOKEN_ID_TRANSFERS_FAILURE = "GET_WALLET_TOKEN_ID_TRANSFERS_FAILURE";  


export const RESOLVE_DOMAIN_REQUEST = "RESOLVE_DOMAIN_REQUEST";
export const RESOLVE_DOMAIN_SUCCESS = "RESOLVE_DOMAIN_SUCCESS";
export const RESOLVE_DOMAIN_FAILURE = "RESOLVE_DOMAIN_FAILURE";  


export const RESOLVE_ADDRESS_REQUEST = "RESOLVE_ADDRESS_REQUEST";
export const RESOLVE_ADDRESS_SUCCESS = "RESOLVE_ADDRESS_SUCCESS";
export const RESOLVE_ADDRESS_FAILURE = "RESOLVE_ADDRESS_FAILURE";  


export const GET_PAIR_RESERVES_REQUEST = "GET_PAIR_RESERVES_REQUEST";
export const GET_PAIR_RESERVES_SUCCESS = "GET_PAIR_RESERVES_SUCCESS";
export const GET_PAIR_RESERVES_FAILURE = "GET_PAIR_RESERVES_FAILURE";  


export const GET_PAIR_ADDRESS_REQUEST = "GET_PAIR_ADDRESS_REQUEST";
export const GET_PAIR_ADDRESS_SUCCESS = "GET_PAIR_ADDRESS_SUCCESS";
export const GET_PAIR_ADDRESS_FAILURE = "GET_PAIR_ADDRESS_FAILURE";  


export const GET_NFT_TRANSFERS_REQUEST = "GET_NFT_TRANSFERS_REQUEST";
export const GET_NFT_TRANSFERS_SUCCESS = "GET_NFT_TRANSFERS_SUCCESS";
export const GET_NFT_TRANSFERS_FAILURE = "GET_NFT_TRANSFERS_FAILURE";  


export const GET_NFTS_REQUEST = "GET_NFTS_REQUEST";
export const GET_NFTS_SUCCESS = "GET_NFTS_SUCCESS";
export const GET_NFTS_FAILURE = "GET_NFTS_FAILURE";  


export const GET_NFT_REQUEST = "GET_NFT_REQUEST";
export const GET_NFT_SUCCESS = "GET_NFT_SUCCESS";
export const GET_NFT_FAILURE = "GET_NFT_FAILURE";  


export const GET_NFT_TRANSFERS_BY_BLOCK_REQUEST = "GET_NFT_TRANSFERS_BY_BLOCK_REQUEST";
export const GET_NFT_TRANSFERS_BY_BLOCK_SUCCESS = "GET_NFT_TRANSFERS_BY_BLOCK_SUCCESS";
export const GET_NFT_TRANSFERS_BY_BLOCK_FAILURE = "GET_NFT_TRANSFERS_BY_BLOCK_FAILURE";  


export const GET_NFT_TRADES_REQUEST = "GET_NFT_TRADES_REQUEST";
export const GET_NFT_TRADES_SUCCESS = "GET_NFT_TRADES_SUCCESS";
export const GET_NFT_TRADES_FAILURE = "GET_NFT_TRADES_FAILURE";  


export const GET_NFT_TRANSFERS_FROM_TO_BLOCK_REQUEST = "GET_NFT_TRANSFERS_FROM_TO_BLOCK_REQUEST";
export const GET_NFT_TRANSFERS_FROM_TO_BLOCK_SUCCESS = "GET_NFT_TRANSFERS_FROM_TO_BLOCK_SUCCESS";
export const GET_NFT_TRANSFERS_FROM_TO_BLOCK_FAILURE = "GET_NFT_TRANSFERS_FROM_TO_BLOCK_FAILURE";  


export const GET_CONTRACT_NFT_TRANSFERS_REQUEST = "GET_CONTRACT_NFT_TRANSFERS_REQUEST";
export const GET_CONTRACT_NFT_TRANSFERS_SUCCESS = "GET_CONTRACT_NFT_TRANSFERS_SUCCESS";
export const GET_CONTRACT_NFT_TRANSFERS_FAILURE = "GET_CONTRACT_NFT_TRANSFERS_FAILURE";  


export const GET_NFT_OWNERS_REQUEST = "GET_NFT_OWNERS_REQUEST";
export const GET_NFT_OWNERS_SUCCESS = "GET_NFT_OWNERS_SUCCESS";
export const GET_NFT_OWNERS_FAILURE = "GET_NFT_OWNERS_FAILURE";  


export const SEARCH_NFTS_REQUEST = "SEARCH_NFTS_REQUEST";
export const SEARCH_NFTS_SUCCESS = "SEARCH_NFTS_SUCCESS";
export const SEARCH_NFTS_FAILURE = "SEARCH_NFTS_FAILURE";  


export const GET_NFT_LOWEST_PRICE_REQUEST = "GET_NFT_LOWEST_PRICE_REQUEST";
export const GET_NFT_LOWEST_PRICE_SUCCESS = "GET_NFT_LOWEST_PRICE_SUCCESS";
export const GET_NFT_LOWEST_PRICE_FAILURE = "GET_NFT_LOWEST_PRICE_FAILURE";  


export const GET_NFT_METADATA_REQUEST = "GET_NFT_METADATA_REQUEST";
export const GET_NFT_METADATA_SUCCESS = "GET_NFT_METADATA_SUCCESS";
export const GET_NFT_METADATA_FAILURE = "GET_NFT_METADATA_FAILURE";  


//SAXON_MORALIS ENDS

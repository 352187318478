import type { Reducer } from "@reduxjs/toolkit";
import { User } from "App/src/types/api/User";
import {
	GET_PRIVATE_PLACEMENTS_TERMS,
	GET_PRIVATE_PLACEMENTS_TERM,
	CREATE_PRIVATE_PLACEMENTS_TERM,
	UPDATE_PRIVATE_PLACEMENTS_TERM,
	DELETE_PRIVATE_PLACEMENTS_TERM,
	GET_PRIVATE_PLACEMENTS_TERM_TRANCHES,
	GET_PRIVATE_PLACEMENTS_TERM_TRANCHE,
	CREATE_PRIVATE_PLACEMENTS_TERM_TRANCHE,
	UPDATE_PRIVATE_PLACEMENTS_TERM_TRANCHE,
	DELETE_PRIVATE_PLACEMENTS_TERM_TRANCHE,
	DELETE_PRIVATE_PLACEMENTS_TERM_TRANCHES,
	PRIVATE_PLACEMENTS_ERROR,
	PRIVATE_PLACEMENTS_ADMIN_ERROR,
	PRIVATE_PLACEMENTS_SHOW_SIDEBAR_LOADER,
	PRIVATE_PLACEMENTS_SHOW_WIDGET_LOADER,
	PRIVATE_PLACEMENTS_SHOW_LOADER,
} from "../actions/types/types";

import { config } from "Config/config";
import { Deal } from "App/src/types/api/Deal";
import { Company } from "App/src/types/api/Company";
import { PPTerm, PPTranche } from "App/src/types/api/PP";

const SaxonData = window.SaxonData?.admin?.privatePlacements;

type IState = {
	currentUser: User;
	companyid: Company["_id"];
	dealid: Deal["_id"];
	userid: User["_id"];
	allTerms: PPTerm<true, true, true>[];
	terms: PPTerm<true, true, true>;
	tranches: PPTranche[];
	tranche: PPTranche;
	loading: boolean;
	sidebarLoading: boolean;
	widgetLoading: boolean;
	error: boolean;
	defaultPaymentTokens: typeof config.PRIVATE_PLACEMENTS_DEFAULT_PAYMENT_TOKENS;
};

const initialState: IState = {
	currentUser: SaxonData?.user,
	companyid: SaxonData?.companyid,
	dealid: SaxonData?.dealid,
	userid: SaxonData?.userid,
	allTerms: [],
	terms: null,
	tranches: [],
	tranche: null,
	loading: true,
	sidebarLoading: true,
	widgetLoading: true,
	error: false,
	defaultPaymentTokens: config.PRIVATE_PLACEMENTS_DEFAULT_PAYMENT_TOKENS,
};

export const privatePlacementsReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case PRIVATE_PLACEMENTS_SHOW_LOADER:
			return {
				...state,
				loading: true,
			};
		case PRIVATE_PLACEMENTS_SHOW_SIDEBAR_LOADER:
			return {
				...state,
				sidebarLoading: true,
			};
		case PRIVATE_PLACEMENTS_SHOW_WIDGET_LOADER:
			return {
				...state,
				widgetLoading: true,
			};
		case GET_PRIVATE_PLACEMENTS_TERMS:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case GET_PRIVATE_PLACEMENTS_TERM:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case CREATE_PRIVATE_PLACEMENTS_TERM:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case UPDATE_PRIVATE_PLACEMENTS_TERM:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case DELETE_PRIVATE_PLACEMENTS_TERM:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case GET_PRIVATE_PLACEMENTS_TERM_TRANCHES:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case GET_PRIVATE_PLACEMENTS_TERM_TRANCHE:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case CREATE_PRIVATE_PLACEMENTS_TERM_TRANCHE:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				...payload,
			};
		case UPDATE_PRIVATE_PLACEMENTS_TERM_TRANCHE:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case DELETE_PRIVATE_PLACEMENTS_TERM_TRANCHE:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case DELETE_PRIVATE_PLACEMENTS_TERM_TRANCHES:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				...payload,
			};
		case PRIVATE_PLACEMENTS_ADMIN_ERROR:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				error: payload,
			};
		case PRIVATE_PLACEMENTS_ERROR:
			return {
				...state,
				loading: false,
				sidebarLoading: false,
				widgetLoading: false,
				error: payload,
			};
		default:
			return state;
	}
};

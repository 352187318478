import React, { useState, useEffect } from "react";

import SnazzyBurgerMenu from "Components/universal/SnazzyBurgerMenu";
// import SnazzyButton from "../universal/SnazzyButton";
import SnazzyButtonLink from "Components/universal/SnazzyButtonLink";

import { Navbar, Nav, NavLink } from "react-bootstrap";
import config from "Config/config";
import PropTypes from "prop-types";
import classNames from "classnames";
// import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Slide from "@mui/material/Slide";
// import Collapse from "@mui/material/Collapse";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const routes = config.PUBLIC_NAV_ROUTES;

const LandingMenu = ({ menuWrapClassName, togglerClassName, menuClass, menuVisibile, menuStyle }) => {
	const theme = useTheme();
	const [open, setOpen] = useState(menuVisibile);
	const [openIndex, setOpenIndex] = useState(menuVisibile ? 0 : 1);
	const isLarge = useMediaQuery(theme.breakpoints.up("lg"));

	const sizeIndex = isLarge ? 0 : 1;
	const togglerStyles = {
		cursor: "pointer",
		width: "3.75rem",
		height: 50,
		// paddingTop: ".5625rem",
		paddingTop: ".75rem",
		paddingRight: ".75rem",
	};
	const navItemClass = [
		//open
		// [large,small]
		["", "border-secondary border-top py-2 py-md-2"],
		//closed
		// [large,small]
		["", ""],
	];
	const navLinkClass = ["px-2 text-center nowrap pt-2", "px-2 text-center nowrap pt-2"];

	const navLinkTextClass = ["text-uppercase align-bottom nowrap py-1 text-snow-hover text-sg-500 hover-underline-animation", "text-uppercase  nowrap py-1 align-bottom"];

	const handleClick = (isOpen) => {
		setOpen(isOpen);
		setOpenIndex(isOpen ? 0 : 1);
	};

	useEffect(() => {
		!isLarge && open ? (document.body.style.overflow = "hidden") : (document.body.style.overflowY = "auto");
	}, [open, isLarge]);

	return (
		<div className={classNames(menuWrapClassName, open ? "bg-dark" : "bg-transparent")}>
			<div className={classNames(togglerClassName)} style={togglerStyles}>
				<SnazzyBurgerMenu isOpen={open} onClick={() => handleClick(!open)} />
			</div>
			<Slide direction="down" in={open} transition={{ enter: "ease-in", exit: "ease-out" }} timeout={{ enter: 700, exit: 500 }} mountOnEnter>
				{
					<Navbar style={menuStyle} bg={isLarge ? "transparent" : "dark"} variant="dark" className={classNames(menuClass, isLarge ? "align-items-center" : "h-100 align-items-stretch ")}>
						<div className="d-flex flex-column flex-lg-row flex-fill justify-content-start align-items-center">
							<div className="d-flex jusitify-content-lg-center align-items-center">
								{/* <div className="justify-content-center justify-content-lg-start"> */}
								<Navbar.Brand href="/" className="justify-content-start justify-content-lg-start align-self-center align-items-center pt-2 mr-0 mr-lg-3">
									<img src={config.SAXON_LOGO} width={isLarge ? "150" : "150"} alt="saxon-logo" className="d-inline-block align-top align-self-lg-center" />
								</Navbar.Brand>
								{/* </div> */}
							</div>

							<div className={classNames("d-flex jusitify-content-lg-start jusitify-content-between ", isLarge ? "" : "py-3 flex-fill w-100")}>
								<Navbar.Collapse id="basic-navbar-nav" className={classNames("justify-content-center justify-content-lg-start align-items-center ", "flex-column ")}>
									<Nav className=" me-auto d-flex flex-column flex-lg-row  justify-content-end justify-content-lg-start flex-fill w-100">
										<Nav.Item className={classNames(navItemClass[openIndex][sizeIndex], isLarge ? "" : "mt-auto")}>
											<NavLink href={routes.about.href} className={classNames(navLinkClass[openIndex])}>
												<span className={classNames(navLinkTextClass[openIndex])}>{routes.about.title}</span>
											</NavLink>
										</Nav.Item>

										<Nav.Item className={classNames(navItemClass[openIndex][sizeIndex])}>
											<NavLink href={routes.advisors.href} className={classNames(navLinkClass[openIndex])}>
												<span className={classNames(navLinkTextClass[openIndex])}>{routes.advisors.title}</span>
											</NavLink>
										</Nav.Item>
										<Nav.Item className={classNames(navItemClass[openIndex][sizeIndex])}>
											<NavLink href={routes.nodes.href} className={classNames(navLinkClass[openIndex])}>
												<span className={classNames(navLinkTextClass[openIndex])}>{routes.nodes.title}</span>
											</NavLink>
										</Nav.Item>
										<Nav.Item className={classNames(navItemClass[openIndex][sizeIndex])}>
											<NavLink href={routes.ventures.href} className={classNames(navLinkClass[openIndex])}>
												<span className={classNames(navLinkTextClass[openIndex])}>{routes.ventures.title}</span>
											</NavLink>
										</Nav.Item>
										<Nav.Item className={classNames(navItemClass[openIndex][sizeIndex])}>
											<NavLink href={routes.careers.href} className={classNames(navLinkClass[openIndex])}>
												<span className={classNames(navLinkTextClass[openIndex])}>{routes.careers.title}</span>
											</NavLink>
										</Nav.Item>
										<Nav.Item className={classNames(navItemClass[openIndex][sizeIndex])}>
											<NavLink href={routes.blog.href} className={classNames(navLinkClass[openIndex])}>
												<span className={classNames(navLinkTextClass[openIndex])}>{routes.blog.title}</span>
											</NavLink>
										</Nav.Item>
										{/* {!isLarge && (
                      <Nav.Item className={classNames(navItemClass[openIndex][sizeIndex])}>
                        <NavLink
                          href={routes.register.href}
                          className={classNames(navLinkClass[openIndex])}>
                          <span className={classNames(navLinkTextClass[openIndex])}>{routes.register.title}</span>
                        </NavLink>
                      </Nav.Item>
                    )} */}
										{isLarge ? (
											<>
												<Nav.Item className={classNames(navItemClass[openIndex][sizeIndex])}>
													<NavLink href={routes.login.href} className={classNames(navLinkClass[openIndex])}>
														<span className={classNames(navLinkTextClass[openIndex])}>{routes.login.title}</span>
													</NavLink>
												</Nav.Item>
											</>
										) : (
											<div className={classNames("text-center", "mt-auto", "pb-5")}>
												<div className={classNames("")}>
													{/* <Link href="/users/login" underline="none"> */}
													<SnazzyButtonLink href={routes.login.href}>{routes.login.title}</SnazzyButtonLink>
													{/* </Link> */}
												</div>
											</div>
										)}
									</Nav>
								</Navbar.Collapse>
							</div>
						</div>
					</Navbar>
				}
			</Slide>
		</div>
	);
};

LandingMenu.defaultProps = {
	menuWrapClassName: "position-fixed z-index-800 end-0 top-0",
	togglerClassName: "position-absolute end-0 top-0 z-index-900",
	menuClass: "position-fixed end-0 top-0 w-100 d-flex justify-content-center px-3",
	menuStyle: { backdropFilter: "grayscale(0.05) blur(5px)" },
	menuVisibile: false,
};

LandingMenu.propTypes = {
	menuWrapClassName: PropTypes.string,
	togglerClassName: PropTypes.string,
	menuClass: PropTypes.string,
	menuVisibile: PropTypes.bool,
	menuStyle: PropTypes.object,
};

export default LandingMenu;

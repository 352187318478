// // pretty sure this isnt used anywhere in swaps actions
// export const GET_TOKEN_HOLDERS_AND_TRANSFERS = "GET_TOKEN_HOLDERS_AND_TRANSFERS";
// export const GET_0X_QUOTE_REQUEST = "GET_0X_QUOTE_REQUEST";
// export const GET_0X_QUOTE_SUCCESS = "GET_0X_QUOTE_SUCCESS";
// export const GET_0X_QUOTE_FAILURE = "GET_0X_QUOTE_FAILURE";
// export const ERROR_0X_QUOTE_TOOLTIP = "ERROR_0X_QUOTE_TOOLTIP";
// export const GET_PROVIDER = "GET_PROVIDER";
// export const GET_APPROVED_TOKENS = "GET_APPROVED_TOKENS";
// export const CREATE_APPROVED_TOKEN = "CREATE_APPROVED_TOKEN";
// export const SWITCH_ASSETS = "SWITCH_ASSETS";
// export const SWITCH_ASSETS_ERROR = "SWITCH_ASSETS_ERROR";
// export const SET_BUY_AMOUNT = "SET_BUY_AMOUNT";
// export const ERROR_BUY_AMOUNT = "ERROR_BUY_AMOUNT";
// export const SET_SELL_AMOUNT = "SET_SELL_AMOUNT";
// export const ERROR_SELL_AMOUNT = "ERROR_SELL_AMOUNT";
// export const PERFORM_SWAP = "PERFORM_SWAP";
// export const SWAP_PENDING = "SWAP_PENDING";
// export const SWAP_SUCCESS = "SWAP_SUCCESS";
// export const ERROR_PERFORM_SWAP = "ERROR_PERFORM_SWAP";
// // end

export const GET_WALLET_REQUEST = "GET_WALLET_REQUEST";
export const GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS";
export const GET_WALLET_ERROR = "GET_WALLET_ERROR";

export const SWAP_RESET_REQUEST = "SWAP_RESET_REQUEST";
export const SWAP_RESET_SUCCESS = "SWAP_RESET_SUCCESS";
export const SWAP_RESET_ERROR = "SWAP_RESET_ERROR";

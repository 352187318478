import { legacy_createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {};

const middleware = [thunk];

let enhancers;

if (process.env.NODE_ENV === "development") {
	enhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
	enhancers = enhancers(applyMiddleware(...middleware));
} else {
	enhancers = applyMiddleware(...middleware);
}

const store = legacy_createStore(rootReducer, initialState, enhancers);

export default store;

export const GET_ADMIN_TEARSHEET_REQUEST = "GET_ADMIN_TEARSHEET_REQUEST";
export const GET_ADMIN_TEARSHEET_SUCCESS = "GET_ADMIN_TEARSHEET_SUCCESS";
export const GET_ADMIN_TEARSHEET_FAILURE = "GET_ADMIN_TEARSHEET_FAILURE";

export const GET_ADMIN_TEARSHEETS_REQUEST = "GET_ADMIN_TEARSHEETS_REQUEST";
export const GET_ADMIN_TEARSHEETS_SUCCESS = "GET_ADMIN_TEARSHEETS_SUCCESS";
export const GET_ADMIN_TEARSHEETS_FAILURE = "GET_ADMIN_TEARSHEETS_FAILURE";

export const CREATE_ADMIN_TEARSHEET_REQUEST = "CREATE_ADMIN_TEARSHEET_REQUEST";
export const CREATE_ADMIN_TEARSHEET_SUCCESS = "CREATE_ADMIN_TEARSHEET_SUCCESS";
export const CREATE_ADMIN_TEARSHEET_FAILURE = "CREATE_ADMIN_TEARSHEET_FAILURE";

export const UPDATE_ADMIN_TEARSHEET_REQUEST = "UPDATE_ADMIN_TEARSHEET_REQUEST";
export const UPDATE_ADMIN_TEARSHEET_SUCCESS = "UPDATE_ADMIN_TEARSHEET_SUCCESS";
export const UPDATE_ADMIN_TEARSHEET_FAILURE = "UPDATE_ADMIN_TEARSHEET_FAILURE";

export const DELETE_ADMIN_TEARSHEET_REQUEST = "DELETE_ADMIN_TEARSHEET_REQUEST";
export const DELETE_ADMIN_TEARSHEET_SUCCESS = "DELETE_ADMIN_TEARSHEET_SUCCESS";
export const DELETE_ADMIN_TEARSHEET_FAILURE = "DELETE_ADMIN_TEARSHEET_FAILURE";

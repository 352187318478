import type { Reducer } from "@reduxjs/toolkit";
import {
	GET_SHORT_URL_FAILURE,
	GET_SHORT_URL_REQUEST,
	GET_SHORT_URL_SUCCESS,
	CREATE_SHORT_URL_FAILURE,
	CREATE_SHORT_URL_REQUEST,
	CREATE_SHORT_URL_SUCCESS,
	DELETE_SHORT_URL_FAILURE,
	DELETE_SHORT_URL_REQUEST,
	DELETE_SHORT_URL_SUCCESS,
	UPDATE_SHORT_URL_FAILURE,
	UPDATE_SHORT_URL_REQUEST,
	UPDATE_SHORT_URL_SUCCESS,
} from "App/src/js/actions/types/shortUrl";
import { ShortUrl } from "App/src/types/api/ShortUrl";

interface IState {
	loading: boolean;
	error: Error;
	shortUrl: ShortUrl;
}

const initialState: IState = {
	loading: true,
	error: null,
	shortUrl: null,
};

export const shortUrlReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_SHORT_URL_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_SHORT_URL_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_SHORT_URL_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case CREATE_SHORT_URL_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case CREATE_SHORT_URL_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};

		case CREATE_SHORT_URL_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case UPDATE_SHORT_URL_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case UPDATE_SHORT_URL_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};

		case UPDATE_SHORT_URL_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case DELETE_SHORT_URL_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case DELETE_SHORT_URL_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};

		case DELETE_SHORT_URL_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		default:
			return state;
	}
};

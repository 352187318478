import { PaletteOptions } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { BreakpointsOptions, Components, Theme, createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { alpha } from "@mui/system";
import type { ButtonProps, Interpolation } from "@mui/material";

import type {} from "@mui/x-data-grid/themeAugmentation";

const createButtonVariant = (colorName: ButtonProps["color"], colorValue: string) => {
	if (!colorName || !colorValue) throw new Error("Invalid color name or value");
	type Result = {
		props: Partial<ButtonProps>;
		style: Interpolation<{
			theme: Theme;
		}>;
	};
	const result: Result = {
		props: {
			variant: "lightened",
			color: colorName,
		},
		style: {
			backgroundColor: `${alpha(colorValue, 0.15)} !important`,
			color: `${colorValue} !important`,
			"&:hover": {
				backgroundColor: `${alpha(colorValue, 0.25)} !important`,
			},
		},
	};
	return result;
};

const darkShades = {
	n900: "#05090e",
	n800: "#060c11",
	n700: "#070e15",
	n600: "#081119",
	n500: "#0a131d",
	n400: "#0b1621",
	n300: "#0c1825",
	n200: "#0d1b28",
	n150: "#0e1c2a",
	n100: "#0f1d2c",
	0: "#102030",
	100: "#132538",
	150: "#14283b",
	200: "#152a3f",
	300: "#162d43",
	400: "#182f47",
	500: "#1d3a56",
	600: "#1f3f5e",
	700: "#224466",
	800: "#24496d",
	900: "#274e75",
};
const contrastDarkText = darkShades[100];
const contrastLightText = "#fff";
const primaryDark = {
	50: "#E2EDF8",
	100: "#CEE0F3",
	200: "#91B9E3",
	300: "#5090D3",
	400: "#265D97",
	500: "#1E4976",
	600: "#173A5E",
	700: "#132F4C",
	800: "#001E3C",
	900: "#0A1929",
	main: "#19abff",
	light: "#66B2FF",
	dark: "#0059B2",
	contrastText: "rgba(255, 255, 255, 0.87)",
};
const sg = {
	50: "#e7eaed",
	100: "#c4cad1",
	200: "#9da6b3",
	300: "#758294",
	400: "#58687d",
	500: "#3a4d66",
	600: "#34465e",
	700: "#2c3d53",
	800: "#253449",
	900: "#182538",
	light: "#c4cad1",
	dark: "#2c3d53",
	main: "#3a4d66",
	contrastText: contrastLightText,
};
const primary = {
	50: "#d8f1ff",
	100: "#b2e3ff",
	200: "#8cd5ff",
	300: "#65c7ff",
	400: "#3fb9ff",
	500: "#19abff",
	600: "#1488cc",
	700: "#0f6699",
	800: "#0a4466",
	900: "#052233",
	main: "#1aabff",
	light: "#65c7ff",
	dark: "#1488cc",
	contrastText: "rgba(0, 0, 0, 0.87)",
};
const secondary = {
	50: "#dff5ff",
	100: "#bfebff",
	200: "#9fe1ff",
	300: "#7fd7ff",
	400: "#5fcdff",
	500: "#40c4ff",
	600: "#339ccc",
	700: "#267599",
	800: "#194e66",
	900: "#0c2733",
	main: "#40c4ff",
	light: "#7fd7ff",
	dark: "#339ccc",
	contrastText: "rgba(0, 0, 0, 0.87)",
};
const light = {
	main: "#a2bad9",
	dark: "#3a4d66",
	light: "#c1cddc",
	contrastText: contrastDarkText,
};
const warning = {
	main: "#ffbc00",
};
const info = {
	100: "#9fe1ff",
	200: "#79d5ff",
	300: "#66cfff",
	400: "#53c9ff",
	500: "#39b0e5",
	600: "#2c89b2",
	700: "#267599",
	800: "#20627f",
	900: "#194e66",
	light: "#9fe1ff",
	dark: "#267599",
	main: "#40c4ff",
	contrastText: "rgba(0, 0, 0, 0.87)",
};
const teal = {
	main: "#06d7e7",
	light: "#37dfeb",
	dark: "#06d7e7",
	contrastText: contrastDarkText,
};
const success = {
	main: "#52e3c2",
	contrastText: contrastDarkText,
};
const error = {
	main: "#ff679b",
	contrastText: contrastDarkText,
};
const important = {
	main: "#ff679b",
	dark: "#ff4d80",
	light: "#ff8fb3",
	contrastText: contrastDarkText,
};
const tertiary = {
	main: "#727cf5",
	dark: "#5b6ee8",
	light: "#8a96f2",
	contrastText: contrastDarkText,
};
const water = {
	main: "#90caf9",
	dark: "#80c6ff",
	light: "#b3daff",
	contrastText: "rgba(0, 0, 0, 0.87)",
};
const ocean = {
	main: "#5ba1e7",
	dark: "#4d8fd1",
	light: "#7bb5f7",
	contrastText: "rgba(0, 0, 0, 0.87)",
};
const navy = {
	n900: "#05090e",
	n800: "#060c11",
	n700: "#070e15",
	n600: "#081119",
	n500: "#0a131d",
	n400: "#0b1621",
	n300: "#0c1825",
	n200: "#0d1b28",
	n150: "#0e1c2a",
	n100: "#0f1d2c",
	main: "#102030",
	100: "#132538",
	150: "#14283b",
	200: "#152a3f",
	300: "#162d43",
	400: "#182f47",
	500: "#1d3a56",
	600: "#1f3f5e",
	700: "#224466",
	800: "#24496d",
	900: "#274e75",
	light: "#274e75",
	dark: "#0f1d2c",
	contrastText: contrastLightText,
};
const dark = {
	n900: "#05090e",
	n800: "#060c11",
	n700: "#070e15",
	n600: "#081119",
	n500: "#0a131d",
	n400: "#0b1621",
	n300: "#0c1825",
	n200: "#0d1b28",
	n150: "#0e1c2a",
	n100: "#0f1d2c",
	main: "#102030",
	100: "#132538",
	150: "#14283b",
	200: "#152a3f",
	300: "#162d43",
	400: "#182f47",
	500: "#1d3a56",
	600: "#1f3f5e",
	700: "#224466",
	800: "#24496d",
	900: "#274e75",
	light: "#274e75",
	dark: "#0f1d2c",
	contrastText: contrastLightText,
};
const grey = {
	50: "#eceff1",
	100: "#cfd8dc",
	200: "#b0bec5",
	300: "#90a4ae",
	400: "#78909c",
	500: "#607d8b",
	600: "#546e7a",
	700: "#455a64",
	800: "#37474f",
	900: "#263238",
	A100: "#cfd8dc",
	A200: "#b0bec5",
	A400: "#78909c",
	A700: "#455a64",
};
const blueGray = {
	...blueGrey,
	main: blueGrey[500],
	light: blueGrey[300],
	dark: blueGrey[700],
	contrastText: "rgba(255, 255, 255, 0.87)",
};

const palette: PaletteOptions = {
	mode: "dark",
	primary: primary,
	primaryDark: primaryDark,
	blueGrey: blueGray,
	secondary: secondary,
	light: light,
	warning: warning,
	sg: sg,
	info: info,
	teal: teal,
	success: success,
	error: error,
	important: important,
	tertiary: tertiary,
	water: water,
	ocean: ocean,
	navy: navy,
	dark: dark,
	grey: grey,
	text: {
		primary: "#c1cddc",
		secondary: "#a2bad9",
		disabled: "#3a4d66",
	},
	background: {
		default: "#182533",
		paper: "#14283b",
	},
	divider: "#3a4d66",
	common: {
		black: "#1D1D1D",
		white: "#fff",
	},
	contrastThreshold: 3,
	tonalOffset: 0.2,
	action: {
		active: "#fff",
		hover: "rgba(255, 255, 255, 0.08)",
		hoverOpacity: 0.08,
		selected: "rgba(255, 255, 255, 0.16)",
		selectedOpacity: 0.16,
		disabled: "rgba(255, 255, 255, 0.22)",
		disabledBackground: darkShades[800],
		disabledOpacity: 0.38,
		focus: "rgba(255, 255, 255, 0.12)",
		focusOpacity: 0.12,
		activatedOpacity: 0.24,
	},
};
const typography: TypographyOptions = {
	fontFamily: "Inter",
	h1: {
		fontSize: "2.25rem",
		lineHeight: "1.75rem",
	},
	h2: {
		fontSize: "2rem",
		lineHeight: "1.75rem",
	},
	h3: {
		fontSize: "1.75rem",
		lineHeight: "1.75rem",
	},
	h4: {
		fontSize: "1.5rem",
		lineHeight: "1.75rem",
	},
	h5: {
		fontSize: "1.25rem",
		lineHeight: "1.75rem",
		fontWeight: 500,
	},
	h6: {
		fontSize: "1.15rem",
		lineHeight: "1.75rem",
	},
	subtitle1: {
		lineHeight: "1.5rem",
	},
	fontWeightBold: 500,
	subtitle2: {
		lineHeight: "1.5rem",
	},
	fontSize: 14,
	fontWeightRegular: 300,
	fontWeightMedium: 400,
	fontWeightLight: 200,
};
const shape = {
	borderRadius: 5,
};
const components: Components = {
	MuiPaper: {
		styleOverrides: {
			root: {
				backgroundColor: darkShades[300],
				backgroundImage: "none",
			},
		},
	},

	MuiSkeleton: {
		styleOverrides: {
			root: {
				backgroundColor: "rgba(255, 255, 255, 0.08)",
			},
		},
	},
	MuiDataGrid: {
		styleOverrides: {
			root: {
				borderWidth: 0,
			},
			// toolbarContainer: {
			// 	padding: 0,
			// },
			paper: {
				background: darkShades[300],
				backgroundImage: "none",
			},
			virtualScroller: {
				"&::-webkit-scrollbar": {
					height: 5,
				},
				"&::-webkit-scrollbar-track": {
					background: "transparent",
					boxShadow: "inset 0 0 5px #274e75",
				},
			},
		},
	},
	MuiCard: {
		styleOverrides: {
			root: {
				backgroundColor: darkShades[300],
			},
		},
	},
	MuiAutocomplete: {
		styleOverrides: {
			endAdornment: {
				top: "calc(50% - 1em)",
			},
			listbox: {
				backgroundColor: "var(--dark-100)",
			},
			paper: {
				backgroundColor: "var(--dark-100)",
			},

			option: {
				backgroundColor: "var(--dark-100)",

				root: {
					backgroundColor: "var(--dark-100)",
				},
				"&:hover": {
					backgroundColor: "var(--dark-300)",
				},
				active: {
					backgroundColor: "var(--dark-300)",
				},
				"&:active": {
					backgroundColor: "var(--dark-300)",
				},
				"&:focus": {
					backgroundColor: "var(--dark-300)",
				},
				"&focus": {
					backgroundColor: "var(--dark-300)",
				},
				selected: {
					backgroundColor: "var(--dark-300)",
				},
				"&[aria-selected='true']": {
					backgroundColor: "var(--dark-300)",
				},
				":current": {
					backgroundColor: "var(--dark-300)",
				},
			},

			groupLabel: {
				backgroundColor: "var(--dark-200)",
			},
		},
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				borderColor: "#3a4d66",
			},
		},
	},
	MuiButton: {
		variants: [
			createButtonVariant("primary", primary.main),
			createButtonVariant("info", info.main),
			createButtonVariant("warning", warning.main),
			createButtonVariant("error", error.main),
			createButtonVariant("success", success.main),
			createButtonVariant("tertiary", tertiary.main),
			createButtonVariant("ocean", ocean.main),
			createButtonVariant("water", water.main),
			createButtonVariant("sg", sg.main),
		],
	},
	
	MuiButtonBase: {
		styleOverrides: {
			root: {
				containedPrimary: {
					disabled: {
						color: "#7d8fa6",
					},
				},
				outlinedPrimary: {
					disabled: {
						color: "#7d8fa6",
					},
				},
			},
		},
	},
	MuiTooltip: {
		styleOverrides: {
			tooltip: {
				backgroundColor: darkShades[500],
			},
		},
	},
	MuiOutlinedInput: {
		styleOverrides: {
			root: {
				borderColor: "#3a4d66",
			},
			notchedOutline: {
				borderColor: "#3a4d66",
			},
		},
	},
	MuiChip: {
		variants: [
			{
				props: {
					variant: "lightened",
					color: "primary",
				},
				style: {
					backgroundColor: `${alpha(primary?.main, 0.25)} !important`,
					color: `${primary?.main} !important`,
				},
			},
			{
				props: {
					variant: "lightened",
					color: "info",
				},
				style: {
					backgroundColor: `${alpha(info?.main, 0.25)} !important`,
					color: `${info?.main} !important`,
				},
			},
			{
				props: {
					variant: "lightened",
					color: "warning",
				},
				style: {
					backgroundColor: `${alpha(warning.main, 0.25)} !important`,
					color: `${warning.main} !important`,
				},
			},
			{
				props: {
					variant: "lightened",
					color: "error",
				},
				style: {
					backgroundColor: `${alpha(error.main, 0.25)} !important`,
					color: `${error.main} !important`,
				},
			},
			{
				props: {
					variant: "lightened",
					color: "success",
				},
				style: {
					backgroundColor: `${alpha(success.main, 0.25)} !important`,
					color: `${success.main} !important`,
				},
			},
			{
				props: {
					variant: "lightened",
					color: "tertiary",
				},
				style: {
					backgroundColor: `${alpha(tertiary.main, 0.25)} !important`,
					color: `${tertiary.main} !important`,
				},
			},
			{
				props: {
					variant: "lightened",
					color: "ocean",
				},
				style: {
					backgroundColor: `${alpha(ocean.main, 0.25)} !important`,
					color: `${ocean.main} !important`,
				},
			},
			{
				props: {
					variant: "lightened",
					color: "water",
				},
				style: {
					backgroundColor: `${alpha(water.main, 0.25)} !important`,
					color: `${water.main} !important`,
				},
			},
			{
				props: {
					variant: "lightened",
					color: "sg",
				},
				style: {
					backgroundColor: `${alpha(sg.main, 0.25)} !important`,
					color: `${sg.main} !important`,
				},
			},
		],
	},
	MuiFilledInput: {
		styleOverrides: {
			root: {
				background: darkShades[500],
				"&:hover": {
					background: darkShades[600],
				},

				"&:focus": {
					background: darkShades[400],
				},
			},
		},
	},
	MuiAvatarGroup: {
		styleOverrides: {
			avatar: {
				circular: {
					borderWidth: "0px",
					borderColor: "transparent",
					boxShadow: "none",
				},
			},
		},
	},
	MuiAvatar: {
		styleOverrides: {
			root: {
				borderColor: "transparent",
				borderWidth: "0px",
				boxShadow: "none",
			},
		},
	},

	MuiToggleButton: {
		styleOverrides: {
			root: {
				"&.MuiToggleButton-primary": {
					textTransform: "none",
					color: "var(--water)",
					fontWeight: 300,
					borderColor: "var(--secondary)",
					"&.Mui-selected": {
						fontWeight: 400,
						borderColor: "var(--secondary)",
						color: "var(--sg-500)",
					},
				},
			},
		},
	},
	MuiListItem: {
		styleOverrides: {
			root: {
				"&:hover": {
					backgroundColor: "rgba(255, 255, 255, 0.05)",
				},
				"&:focus": {
					backgroundColor: "rgba(255, 255, 255, 0.05)",
				},
				"&:active": {
					backgroundColor: "rgba(255, 255, 255, 0.05)",
				},
				"&.Mui-selected": {
					backgroundColor: "rgba(255, 255, 255, 0.05)",
				},
			},
		},
	},
	MuiDialog: {
		styleOverrides: {
			paper: {
				borderRadius: "0.7rem",
			},
		},
	},
};
const breakpoints: BreakpointsOptions = {
	keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
	values: {
		xs: 0,
		sm: 576,
		md: 768,
		lg: 992,
		xl: 1200,
		xxl: 1400,
	},
};

export const theme: Theme = createTheme({
	palette: palette,
	typography: typography,
	shape: shape,
	spacing: 10,
	components: components,
	breakpoints: breakpoints,
});

window.theme = theme;

export default theme;

import type { Reducer } from "@reduxjs/toolkit";
import { GET_TEARSHEETS_FAILURE, GET_TEARSHEETS_REQUEST, GET_TEARSHEETS_SUCCESS, GET_TEARSHEET_FAILURE, GET_TEARSHEET_REQUEST, GET_TEARSHEET_SUCCESS } from "Actions/types/tearsheet";
import { Company } from "App/src/types/api/Company";
import { DataRoom } from "App/src/types/api/DataRoom";
import { Deal } from "App/src/types/api/Deal";
import { Tearsheet } from "App/src/types/api/Tearsheet";
import { isVariable } from "Utils/utils";

interface IState {
	loading: boolean;
	error: Error;
	tearsheet: Tearsheet;
	tearsheets: Tearsheet[];
	companyid: Company["_id"];
	dealid: Deal["_id"];
	dataroom: DataRoom;
}

const initialState: IState = {
	loading: true,
	error: null,
	companyid: window?.SaxonData?.companyid || null,
	dealid: window?.SaxonData?.dealid || null,
	tearsheet: isVariable(window?.SaxonData?.tearsheet?.id) ? window?.SaxonData?.tearsheet : null,
	tearsheets: [],
	dataroom: window?.SaxonData?.dataroom || null,
};

export const tearsheetReducer: Reducer<IState> = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_TEARSHEETS_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_TEARSHEETS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_TEARSHEETS_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		case GET_TEARSHEET_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_TEARSHEET_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				...payload,
			};
		case GET_TEARSHEET_FAILURE:
			return {
				...state,
				loading: false,
				error: payload,
			};

		default:
			return state;
	}
};

import * as React from "react";
import PropTypes from "prop-types";
import SnazzyButtonLink from "Components/universal/SnazzyButtonLink";

const RegisterDapp = ({ textContent = "Register", isLogin = false }) => {
	const href = !isLogin ? "/users/register" : "/users/login";

	return <SnazzyButtonLink href={href}>{textContent}</SnazzyButtonLink>;
};

RegisterDapp.propTypes = {
	textContent: PropTypes.string,
	isLogin: PropTypes.bool,
};

export default RegisterDapp;
